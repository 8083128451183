@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lobster&family=Roboto:wght@400;700&display=swap");

@import "styles/partitions/header.css";
@import "styles/partitions/homepage.css";
@import "styles/partitions/apply-to-coach.css";
@import "styles/partitions/startyourjourney.css";
@import "styles/partitions/surfcoaches.css";
@import "styles/partitions/Profile.css";
@import "styles/partitions/ContactModal.css";
@import "styles/partitions/about.css";
@import "styles/partitions/contact.css";
@import "styles/partitions/footer.css";
@import "styles/partitions/admindashboard.css";
@import "styles/partitions/questions.css";
@import "styles/partitions/bloginfo.css";
@import "styles/partitions/Maui.css";
@import "styles/partitions/terms.css";
@import "styles/partitions/privacy.css";

body,
html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal overflow */
  scroll-behavior: smooth;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 0;
}

#root {
  scroll-behavior: smooth;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Reset scroll position for all pages */
.page-container {
  scroll-behavior: smooth;
  min-height: 100vh;
  width: 100%;
}
