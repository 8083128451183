/* General styles */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  position: relative;
  width: 100%;
}

.header-tags {
  display: flex;
  margin-right: 3rem;
  font-size: 1.4rem;
  color: #0077be;
  transition: transform 0.3s ease; /* Smooth sliding effect */
}

.header h1 {
  font-family: "Lobster", cursive;
  font-size: 2.5rem;
  color: #0077be;
  text-shadow: 1px 1px 2.2px rgba(0, 0, 0, 0.3);
}

.header-tags h3 {
  margin-left: 2.5rem;
}
.mobile-menu-item {
  margin-top: 5rem;
}

.header-tags a,
.header a {
  text-decoration: none;
  color: inherit;
  position: relative;
  font-weight: 500;
  transition: color 0.3s ease, transform 0.3s ease;
}

.header-tags a:visited,
.header a:visited {
  color: inherit;
}

.header-tags a:hover,
.header a:hover {
  color: #0077be;
  transform: scale(1.05);
}

.header-tags a:active,
.header a:active {
  color: #0077be;
  transform: scale(0.95);
}

.header-tags a::after,
.header a::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 0;
  height: 2px;
  background-color: #0077be;
  transition: width 0.3s ease;
}

.header-tags a:hover::after {
  width: 90%;
  margin-left: 2rem;
}

/* Hamburger button */
.hamburger {
  display: none;
  background: none;
  border: none;
  font-size: 1.6rem;
  cursor: pointer;
  color: #0077be;
  padding: 0.7rem 1rem;
  transition: all 0.3s ease;
  border-radius: 5px;
}

.hamburger:hover {
  background-color: rgba(0, 119, 190, 0.1);
  transform: scale(1.1);
}

.hamburger:active {
  transform: scale(0.95);
}

/* Media queries for responsive design */
@media (max-width: 1100px) {
  .header {
    justify-content: space-between;
    padding: 0.8rem 1rem;
    margin: 0;
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .website-title {
    margin-left: 0;
    margin-right: 2rem;
  }

  .header h1 {
    display: block;
    font-size: 1.8rem;
    margin: 0;
    padding-right: 0.5rem;
    margin-left: -3rem;
  }

  .hamburger {
    display: block;
    position: static;
    margin: 0;
    z-index: 1001;
  }

  .header-tags {
    position: fixed;
    top: 0;
    left: 0;
    right: auto;
    background-color: white;
    width: 20rem;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    transform: translateX(-100%);
    height: 100vh;
    z-index: 1000;
  }

  .header-tags.open {
    transform: translateX(0);
  }
}

/* Hide the close button on larger screens */
@media (min-width: 769px) {
  .mobile-menu-close {
    display: none;
  }
}

/* Add these styles for the close button */
.mobile-menu-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.8rem;
  cursor: pointer;
  color: #0077be;
  padding: 0.5rem;
  transition: all 0.3s ease;
}

.mobile-menu-close:hover {
  transform: scale(1.1);
}

.mobile-menu-close:active {
  transform: scale(0.95);
}
