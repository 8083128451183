.layout {
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
}

.footer {
  background: linear-gradient(135deg, #f8f9fa, #e3f2fd);
  padding: 4rem 2rem 2rem;
  position: relative;
  overflow: hidden;
}

.footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(90deg, #0077b6, #00b4d8, #0077b6);
  opacity: 0.8;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 3rem;
  position: relative;
}

.footer-section {
  padding: 1rem;
}

.footer-section h3 {
  color: #0077b6;
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
  position: relative;
  display: inline-block;
}

.footer-section h3::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 40px;
  height: 3px;
  background: #00b4d8;
  border-radius: 2px;
}

.footer-section p {
  color: #4a5568;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.contact-info {
  margin-top: 1rem;
}

.contact-info p {
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  color: #4a5568;
}

.contact-info a {
  color: #0077b6;
  text-decoration: none;
  transition: color 0.3s ease;
  margin-left: 0.3rem;
}

.contact-info a:hover {
  color: #005d91;
  text-decoration: underline;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin: 0.8rem 0;
}

.footer-section a {
  color: #4a5568;
  text-decoration: none;
  transition: color 0.3s ease, transform 0.3s ease;
  display: inline-block;
}

.footer-section a:hover {
  color: #0077b6;
  transform: translateX(5px);
}

.social-links {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.social-icon {
  background: white;
  padding: 0.8rem;
  border-radius: 50%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.social-icon:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.social-icon img {
  width: 1.5rem;
  height: 1.5rem;
}

.newsletter {
  margin-top: 2rem;
}

.newsletter h4 {
  color: #0077b6;
  margin-bottom: 0.5rem;
}

.newsletter-form {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
}

.newsletter-form input {
  padding: 0.8rem;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  flex: 1;
}

.newsletter-form button {
  background: #0077b6;
  color: white;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.newsletter-form button:hover {
  background: #005d91;
}

.footer-bottom {
  text-align: center;
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: 1px solid #e2e8f0;
  color: #4a5568;
}

@media (max-width: 768px) {
  .footer-content {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .footer-section h3::after {
    left: 50%;
    transform: translateX(-50%);
  }

  .social-links {
    justify-content: center;
  }

  .newsletter-form {
    flex-direction: column;
  }

  .contact-info p {
    justify-content: center;
  }
}
