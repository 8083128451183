/* Main Container */
.apply-coach-container {
  min-height: 100vh;
  background: linear-gradient(to bottom, #f8f9fa, #e9ecef);
}

/* Hero Section */
.apply-coach-hero {
  position: relative;
  height: 100vh;
  background-size: cover;
  background-position: 47% center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  overflow: hidden;
  transition: background-image 0.3s ease-in-out;
}

/* Add fade-in animation for hero image */
.apply-coach-hero.image-loaded {
  animation: fadeIn 0.5s ease-in-out;
}

.hero-content-2 {
  z-index: 2;
  max-width: 800px;
  padding: 2rem;
  position: relative;
  z-index: 2;
  text-align: center;
  color: white;
  max-width: 1200px;
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 27rem;
}

.hero-content-2 h1 {
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 1rem;
  animation: fadeInDown 1s ease-out;
}

.hero-content-2 h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  animation: fadeInDown 1s ease-out 0.2s;
  animation-fill-mode: both;
}

.hero-content-2 p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
  animation: fadeInDown 1s ease-out 0.4s;
  animation-fill-mode: both;
}

.cta-button-apply {
  padding: 1rem 2rem;
  font-size: 1.1rem;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.cta-button.primary {
  background: linear-gradient(135deg, #0077b6, #004080);
  color: white;
}

.cta-button-apply.secondary {
  background: white;
  color: #0077b6;
  border: 2px solid #0077b6;
}

.cta-button-apply:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.wave-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 150px;
  background: url('data:image/svg+xml,<svg viewBox="0 0 1200 120" xmlns="http://www.w3.org/2000/svg"><path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" fill="%23ffffff"/></svg>');
  background-size: cover;
}

/* Benefits Section */
.benefits-section {
  padding: 6rem 2rem;
  background: white;
}

.benefits-section h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 4rem;
  color: #2c3e50;
}

.benefits-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2.5rem;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
}

.benefit-card {
  padding: 2.5rem;
  text-align: center;
  background: white;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.benefit-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.15);
}

.benefit-card i {
  font-size: 2.5rem;
  color: #2196f3;
  margin-bottom: 1.5rem;
}

.benefit-card h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #2c3e50;
}

.benefit-card p {
  color: #666;
  line-height: 1.6;
  margin-top: 1rem;
  font-size: 1.1rem;
}

.benefits-footer {
  text-align: center;
  margin-top: 4rem;
  padding: 2rem;
  background: linear-gradient(135deg, #f8f9fa, #e9ecef);
  border-radius: 20px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.benefits-footer p {
  font-size: 1.2rem;
  color: #2c3e50;
  margin-bottom: 1rem;
}

.highlight-text {
  font-weight: 600;
  color: #2196f3;
  font-size: 1.3rem !important;
  margin-top: 1.5rem;
}

/* Application Form Section */
.application-section {
  padding: 6rem 2rem;
  background: linear-gradient(135deg, #f8f9fa, #e3f2fd);
}

.form-container {
  max-width: 1200px;
  margin: 0 auto;
  background: white;
  padding: 4rem;
  border-radius: 30px;
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.1);
}

.form-container h2 {
  text-align: center;
  font-size: 3rem;
  margin-bottom: 3rem;
  color: #2c3e50;
  font-weight: 700;
  background: linear-gradient(135deg, #2196f3, #0d47a1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
}

.form-group {
  margin-bottom: 3rem;
  position: relative;
}

.form-group.full-width {
  grid-column: 1 / -1;
}

.form-group label {
  display: block;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  color: #2196f3;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 1.2rem;
  border: 2px solid #e0e0e0;
  border-radius: 15px;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  margin-bottom: 1.5rem;
  background: #f8f9fa;
  box-sizing: border-box;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #2196f3;
  outline: none;
  box-shadow: 0 0 0 4px rgba(33, 150, 243, 0.1);
  background: white;
}

.form-group input::placeholder,
.form-group textarea::placeholder {
  color: #9e9e9e;
  font-size: 1rem;
}

.price-inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2.5rem;
  margin-bottom: 1.5rem;
}

.price-inputs input {
  width: 100%;
  padding: 1.2rem;
  border: 2px solid #e0e0e0;
  border-radius: 15px;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  background: #f8f9fa;
  box-sizing: border-box;
}

.price-inputs input:focus {
  border-color: #2196f3;
  outline: none;
  box-shadow: 0 0 0 4px rgba(33, 150, 243, 0.1);
  background: white;
}

.file-upload {
  position: relative;
  padding: 3rem;
  border: 3px dashed #2196f3;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  background: rgba(33, 150, 243, 0.05);
}

.file-upload:hover {
  border-color: #1976d2;
  background: rgba(33, 150, 243, 0.1);
  transform: translateY(-2px);
}

.file-upload i {
  font-size: 2.5rem;
  color: #2196f3;
  margin-bottom: 1rem;
}

.file-upload span {
  font-size: 1.1rem;
  color: #2196f3;
  font-weight: 500;
}

.terms-section {
  margin: 3rem 0;
  padding: 2rem;
  background: #f8f9fa;
  border-radius: 15px;
  text-align: center;
}

.checkbox-label {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  font-size: 1.1rem;
  color: #2c3e50;
}

.checkbox-label input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  cursor: pointer;
}

.checkbox-label a {
  color: #2196f3;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.checkbox-label a:hover {
  color: #1976d2;
}

.submit-button {
  display: block;
  width: 100%;
  padding: 1.5rem;
  background: linear-gradient(135deg, #2196f3, #1976d2);
  color: white;
  border: none;
  border-radius: 15px;
  font-size: 1.3rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 3rem;
}

.submit-button:hover {
  background: linear-gradient(135deg, #1976d2, #0d47a1);
  transform: translateY(-2px);
  box-shadow: 0 10px 20px rgba(33, 150, 243, 0.2);
}

.warning-message {
  color: #d32f2f;
  text-align: center;
  margin: 2rem 0;
  padding: 1rem;
  border-radius: 10px;
  background: #ffebee;
  border: 2px solid #ffcdd2;
  font-weight: 500;
  animation: shake 0.5s ease-in-out;
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .form-container {
    padding: 2rem;
  }

  .cta-button {
    padding: 0.5rem 1rem;
  }

  .form-grid {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .form-container h2 {
    font-size: 2.5rem;
  }

  .price-inputs {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  /* Added mobile font size adjustments */
  .hero-content-2 h1 {
    font-size: 2.5rem;
  }

  .hero-content-2 {
    margin-bottom: 20rem;
  }

  .hero-content-2 h2 {
    font-size: 1.8rem;
  }

  .hero-content-2 p {
    font-size: 1rem;
  }
}

@media (min-width: 768px) and (max-width: 1224px) {
  .hero-content-2 {
    margin-bottom: 20rem;
  }
}

/* Success Modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.success-container {
  background: white;
  padding: 3rem;
  border-radius: 20px;
  text-align: center;
  max-width: 500px;
  animation: modalFadeIn 0.5s ease-out;
}

/* Animations */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Loading Overlay */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #2196f3;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.form-group.full-width textarea {
  min-height: 200px;
  resize: vertical;
  line-height: 1.6;
  padding: 1.5rem;
}

.form-group.full-width textarea:focus {
  min-height: 250px;
  transition: min-height 0.3s ease;
}

/* Success Modal Styling */
.apply-success-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  padding: 1rem;
}

.apply-success-modal {
  background: white;
  border-radius: 24px;
  padding: 2rem;
  width: 90%;
  max-width: 500px;
  margin: 1rem;
  position: relative;
  max-height: 90vh;
  overflow-y: auto;
}

.apply-success-content {
  text-align: center;
}

.apply-success-icon {
  font-size: 4rem;
  color: #00c853;
  margin-bottom: 1.5rem;
  animation: iconBounce 0.6s cubic-bezier(0.34, 1.56, 0.64, 1) 0.2s;
  display: inline-block;
}

.apply-success-title {
  font-size: 2.5rem;
  background: linear-gradient(135deg, #2196f3, #0d47a1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1rem;
  font-weight: 700;
  animation: slideUp 0.5s ease-out 0.3s both;
}

.apply-success-message {
  color: #64748b;
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
  animation: slideUp 0.5s ease-out 0.4s both;
}

.apply-success-btn {
  background: linear-gradient(135deg, #2196f3, #1976d2);
  color: white;
  border: none;
  padding: 1rem 3rem;
  border-radius: 50px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  animation: slideUp 0.5s ease-out 0.5s both;
  box-shadow: 0 4px 15px rgba(33, 150, 243, 0.3);
}

.apply-success-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 25px rgba(33, 150, 243, 0.4);
  background: linear-gradient(135deg, #1e88e5, #1565c0);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes modalPop {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes iconBounce {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .apply-success-modal {
    width: 85%;
    padding: 1.5rem;
    margin: 0.5rem;
  }

  .apply-success-message {
    font-size: 1rem;
    padding: 0 0.5rem;
  }

  .loading-overlay {
    z-index: 9999;
  }
}

/* Icon placeholder styling */
.icon-placeholder {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background: #f0f0f0;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}