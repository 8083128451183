.profile-container {
  background: linear-gradient(135deg, #f8f9fa 0%, #e3f2fd 50%, #f0f9ff 100%);
  min-height: 100vh;
  padding: 2rem 4rem;
  position: relative;
}

.profile-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    135deg,
    rgba(0, 119, 182, 0.05) 0%,
    rgba(0, 77, 182, 0.08) 100%
  );
  pointer-events: none;
}

.profile {
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  padding-top: 2rem;
}

.profile-left {
  position: sticky;
  top: 2rem;
  height: fit-content;
  width: 400px;
}

.profile-coach-card {
  background: rgba(255, 255, 255, 0.95);
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  width: 100%;
}

.profile-header {
  background: linear-gradient(135deg, #0077b6, #023e8a);
  color: white;
  padding: 2rem;
  text-align: center;
}

.profile-picture {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  border: 4px solid white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  margin: -40px auto 1rem;
  position: relative;
  z-index: 1;
  object-fit: cover;
}

.profile-coach-card-text {
  padding: 2rem 2rem 1rem;
  text-align: center;
}

.coach-name {
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 0.5rem;
  word-break: break-word;
  overflow-wrap: break-word;
}

.coach-location {
  color: #666;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.rate-card {
  background: #f8f9fa;
  border-radius: 12px;
  padding: 1rem;
  margin: 1rem 0;
}

.rate-title {
  color: #0077b6;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.contact-me {
  background: linear-gradient(135deg, #0077b6, #023e8a);
  color: white;
  width: 100%;
  padding: 1rem;
  border: none;
  border-radius: 10px;
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 4px 15px rgba(0, 119, 182, 0.2);
}

.contact-me:hover {
  background: linear-gradient(135deg, #023e8a, #0077b6);
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 119, 182, 0.3);
}

.profile-experience {
  background: rgba(255, 255, 255, 0.95);
  border-radius: 20px;
  padding: 2rem 3rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  height: fit-content;
  margin-bottom: 2rem;
}

.about-title {
  font-size: 2.2rem;
  color: #0077b6;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
}

.about-title::after {
  content: "";
  bottom: 0;
  left: 0;
  width: 60px;
  height: 4px;
  background: #0077b6;
  border-radius: 2px;
}

.about-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact-info {
  color: #4a5568;
  line-height: 1.8;
  margin-bottom: 1rem;
}

.contact-info:last-of-type {
  margin-bottom: 1.5rem;
}

/* Update review system styling */
.past-review-system {
  background: rgba(255, 255, 255, 0.95);
  border-radius: 20px;
  padding: 3rem;
  margin-top: 3rem;
  box-shadow: 0 15px 40px rgba(0, 119, 182, 0.15);
  transition: transform 0.3s ease;
}

.past-review-system:hover {
  transform: translateY(-5px);
}

.reviews-container {
  position: relative;
}

.coach-name-reviews {
  font-size: 2rem;
  color: #0077b6;
  margin-bottom: 2rem;
  font-family: "Pacifico", cursive;
  text-align: center;
  position: relative;
  padding-bottom: 1rem;
}

.coach-name-reviews::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 3px;
  background: linear-gradient(90deg, transparent, #0077b6, transparent);
}

.header-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem auto 2.5rem;
  gap: 2rem;
  flex-wrap: wrap;
  max-width: 600px;
  margin: 0 auto;
}

.review-button {
  background: linear-gradient(135deg, #0077b6, #023e8a);
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 50px;
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 119, 182, 0.2);
  min-width: 200px;
  text-align: center;
  margin: 0 auto;
}

.review-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 119, 182, 0.3);
  background: linear-gradient(135deg, #023e8a, #0077b6);
}

.sort-select {
  padding: 1rem 2rem;
  border: 2px solid #0077b6;
  border-radius: 50px;
  font-size: 1rem;
  color: #0077b6;
  background: white;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 200px;
  text-align: center;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%230077b6' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1.2em;
  margin: 0 auto;
}

.sort-select:hover {
  border-color: #023e8a;
  box-shadow: 0 4px 15px rgba(0, 119, 182, 0.1);
}

.sort-select:focus {
  outline: none;
  border-color: #023e8a;
  box-shadow: 0 4px 15px rgba(0, 119, 182, 0.2);
}

/* Review Display Styling */
.display-reviews {
  display: grid;
  gap: 2rem;
  margin-top: 2rem;
}

.display-each-review {
  background: linear-gradient(145deg, #ffffff, #f8f9fa);
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  border-left: 4px solid #0077b6;
}

.display-each-review:hover {
  transform: translateY(-3px);
  box-shadow: 0 12px 30px rgba(0, 119, 182, 0.1);
}

.review-name {
  font-size: 1.2rem;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 0.5rem;
}

.star-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  gap: 1rem;
}

.review-rating {
  display: flex;
  gap: 0.3rem;
}

.review-timestamp {
  color: #666;
  font-size: 0.9rem;
  background: rgba(0, 119, 182, 0.1);
  padding: 0.5rem 1rem;
  border-radius: 20px;
}

.review-text {
  color: #4a5568;
  line-height: 1.6;
  font-size: 1.1rem;
}

.no-reviews {
  text-align: center;
  color: #666;
  font-style: italic;
  padding: 2rem;
  background: rgba(0, 119, 182, 0.05);
  border-radius: 10px;
}

/* Media Queries for Review Section */
@media (max-width: 768px) {
  .header-section {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .review-button,
  .sort-select {
    width: 100%;
    max-width: 300px;
  }

  .display-each-review {
    padding: 1.5rem;
  }
}

/* For screens up to 600px (Mobile devices) */
@media (max-width: 600px) {
  .profile-container {
    padding: 1rem;
  }

  .profile {
    flex-direction: column;
    align-items: center;
    height: auto;
    min-height: 160rem;
    background-size: cover;
  }

  .profile-coach-card {
    margin-left: 0;
    margin-top: 2rem;
    width: 95%;
    padding: 1rem;
  }

  .past-review-system {
    margin-left: 0;
    margin-top: 2rem;
    width: 95%;
    padding: 1rem;
  }

  .profile-experience {
    margin-left: 0;
    margin-top: 2rem;
    width: 85%;
    padding: 1rem;
  }

  .profile-picture {
    width: 12.5rem;
    height: 12.5rem;
  }

  .about-title {
    font-size: 2rem;
  }

  .about-content {
    font-size: 1rem;
  }

  .form-input,
  .rating-input,
  .form-textarea {
    width: 90%;
  }

  .past-review-system {
    max-width: 95%;
  }
}

/* For screens between 601px and 1024px (Tablets) */
@media (min-width: 601px) and (max-width: 1024px) {
  .profile-container {
    padding: 2rem;
  }

  .review-close-icon {
    margin-top: 2rem;
  }

  .profile {
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    height: auto;
    min-height: 160rem;
  }

  .profile-coach-card {
    margin-left: 0;
    margin-top: 3rem;
    width: 100%;
  }

  .past-review-system {
    margin-left: 0;
    margin-top: 2rem;
    width: 100%;
  }

  .profile-experience {
    margin-left: 0;
    margin-top: 3rem;
    width: 90%;
  }

  .profile-picture {
    width: 16.25rem;
    height: 16.25rem;
  }

  .about-title {
    font-size: 2.2rem;
  }

  .about-content {
    font-size: 1.1rem;
  }

  .form-input,
  .form-textarea {
    width: 90%;
  }
}

/* For screens 1025px and above (Desktops) */
@media (min-width: 1025px) {
  .profile {
    flex-direction: row;
    justify-content: space-between;
    gap: 3rem;
    height: auto;
    min-height: 110rem;
  }

  .profile-left {
    flex: 0 0 400px;
  }

  .profile-experience {
    flex: 1;
  }

  .profile-coach-card {
    margin-top: 2rem;
    width: 100%;
  }

  .profile-picture {
    width: 18.75rem;
    height: 18.75rem;
  }

  .about-title {
    font-size: 2.5rem;
  }

  .about-content {
    font-size: 1.2rem;
  }
}

.experience-badge-2 {
  background: linear-gradient(135deg, #0077b6, #023e8a);
  color: white;
  padding: 0.8rem 1.5rem;
  border-radius: 50px;
  font-size: 1rem;
  font-weight: 500;
  display: inline-block;
  box-shadow: 0 4px 15px rgba(0, 119, 182, 0.2);
  transition: all 0.3s ease;
  margin-top: 1rem;
}

.experience-badge-2:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 119, 182, 0.3);
}

/* Review Modal Styling */
.review-modal-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 1rem;
  overflow-y: auto;
}

.review-modal-content {
  background: linear-gradient(145deg, #ffffff, #f8f9fa);
  padding: 4rem 3rem 3rem;
  border-radius: 20px;
  width: 100%;
  max-width: 800px;
  position: relative;
  box-shadow: 0 25px 50px -12px rgba(0, 119, 182, 0.25);
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.review-modal-header {
  width: 100%;
  position: relative;
  text-align: center;
  margin-bottom: 2rem;
}

.review-modal-header h2 {
  font-size: 2.5rem;
  color: #0077b6;
  margin-top: 15rem;
  margin-bottom: 1.5rem;
}

.review-close-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #94a3b8;
  padding: 0.5rem;
  transition: all 0.3s ease;
  margin-top: 5rem;
}

.review-close-icon:hover {
  color: #64748b;
  transform: rotate(90deg);
}

.review-form {
  width: 70%;
  margin: 1rem auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.review-form-field {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.review-input,
.review-textarea {
  width: 100%;
  max-width: 100%;
  padding: 1.2rem 1.5rem;
  border: 2px solid #e0e0e0;
  border-radius: 12px;
  font-size: 1rem;
  background: white;
  transition: all 0.3s ease;
  box-sizing: border-box;
  margin: 0.5rem 0;
}

.review-input:focus {
  border-color: #0077b6;
  box-shadow: 0 5px 15px rgba(0, 119, 182, 0.1);
  outline: none;
}

.review-rating-container {
  width: 100%;
  text-align: center;
  margin: 0.5rem 0;
}

.review-rating-label {
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  color: #0077b6;
}

.star-rating-group {
  display: flex;
  justify-content: center;
  gap: 0.8rem;
  margin-top: 1rem;
}

.star-icon {
  cursor: pointer;
  transition: transform 0.2s ease;
}

.star-icon:hover {
  transform: scale(1.2);
}

.star-filled {
  color: #ffd700;
  filter: drop-shadow(0 0 2px rgba(255, 215, 0, 0.4));
}

.review-textarea {
  width: 100%;
  min-height: 150px;
  padding: 1.2rem 1.5rem;
  border: 2px solid #e0e0e0;
  border-radius: 12px;
  font-size: 1rem;
  resize: vertical;
  background: white;
  transition: all 0.3s ease;
}

.review-textarea:focus {
  border-color: #0077b6;
  box-shadow: 0 5px 15px rgba(0, 119, 182, 0.1);
  outline: none;
}

.review-button-group {
  margin-top: 1.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}

.review-submit-btn,
.review-cancel-btn {
  flex: 1;
  max-width: 200px;
  padding: 1.2rem 2rem;
  border-radius: 12px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.review-submit-btn {
  background: linear-gradient(135deg, #0077b6, #023e8a);
  color: white;
  border: none;
}

.review-submit-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 119, 182, 0.3);
}

.review-cancel-btn {
  background: white;
  color: #64748b;
  border: 2px solid #e2e8f0;
}

.review-cancel-btn:hover {
  background: #f8fafc;
  border-color: #cbd5e1;
}

@keyframes reviewModalSlide {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .review-close-icon {
    margin-top: 3rem;
  }
  .review-modal-content {
    padding: 3.5rem 1.5rem 2rem;
    margin: 1rem;
    width: 95%;
  }

  .review-form {
    width: 90%;
    gap: 1.2rem;
  }

  .review-button-group {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .review-submit-btn,
  .review-cancel-btn {
    width: 100%;
    max-width: none;
  }

  .review-modal-header h2 {
    font-size: 2rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  .review-rating-container {
    margin: 1rem 0;
  }
}

.loading {
  text-align: center;
  padding: 2rem;
  font-size: 1.2rem;
  color: #2c3e50;
}

/* Add these styles to your existing Profile.css */
.coach-rating {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  gap: 4px;
}

.rating-text {
  margin-left: 5px;
  color: #666;
  font-size: 14px;
}

.review-count {
  color: #666;
  font-size: 14px;
  margin-left: 5px;
}

.filled {
  color: #ffd700;
}

.empty {
  color: #e4e5e9;
}

/* Update the experience badge margin to work with the new rating */
.experience-badge-2 {
  margin-top: 1rem; /* Reduced from 3rem to work better with rating display */
}
