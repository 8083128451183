@import url("https://fonts.googleapis.com/css2?family=Freehand&family=Metal&display=swap");

.contact-page {
  background: linear-gradient(to bottom, #f8f9fa, #e3f2fd);
  min-height: 100vh;
  height: auto;
  padding-bottom: 6rem;
  position: relative;
  width: 100%;
}

.contact-hero {
  background: linear-gradient(rgba(0, 119, 182, 0.6), rgba(0, 77, 182, 0.7)),
    url("../../../public/images/surf-2.jpg");
  background-size: cover;
  background-position: center;
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  padding: 2rem;
}

.contact-hero h1 {
  font-size: 4rem;
  font-family: "Pacifico", cursive;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.contact-hero p {
  font-size: 1.5rem;
  opacity: 0.9;
}

.contact-content {
  max-width: 1200px;
  margin: 4rem auto;
  padding: 0 2rem;
  position: relative;
  z-index: 1;
}

.contact-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  margin-bottom: 4rem;
}

.contact-info h2,
.contact-form-section h2 {
  font-size: 2.2rem;
  color: #0077b6;
  margin-bottom: 2rem;
  font-family: "Pacifico", cursive;
}

.info-cards {
  display: grid;
  gap: 1.5rem;
}

.info-card {
  background: white;
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.info-card:hover {
  transform: translateY(-5px);
}

.info-card i {
  font-size: 2rem;
  color: #0077b6;
  margin-bottom: 1rem;
}

.info-card h3 {
  font-size: 1.3rem;
  color: #2c3e50;
  margin-bottom: 0.5rem;
}

.info-card p,
.info-card a {
  color: #666;
  font-size: 1.1rem;
  line-height: 1.6;
}

.info-card a {
  color: #0077b6;
  text-decoration: none;
  display: block;
  margin-top: 0.5rem;
  transition: color 0.3s ease;
}

.info-card a:hover {
  color: #004080;
}

.contact-form-section {
  background: white;
  padding: 3rem;
  border-radius: 30px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 1rem;
  border: 2px solid rgba(0, 119, 182, 0.2);
  border-radius: 15px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #0077b6;
}

.submit-button {
  width: 100%;
  padding: 1rem;
  background: linear-gradient(135deg, #0077b6, #004080);
  color: white;
  border: none;
  border-radius: 50px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.submit-button:hover {
  transform: translateY(-2px);
}

.faq-section {
  margin-top: 4rem;
  padding: 4rem;
  background: white;
  border-radius: 30px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.faq-section h2 {
  text-align: center;
  font-size: 2.2rem;
  color: #0077b6;
  margin-bottom: 3rem;
  font-family: "Pacifico", cursive;
}

.faq-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.faq-card {
  padding: 2rem;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.95),
    rgba(255, 255, 255, 0.85)
  );
  border-radius: 20px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.05);
}

.faq-card h3 {
  font-size: 1.3rem;
  color: #0077b6;
  margin-bottom: 1rem;
}

.faq-card p {
  color: #666;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .contact-grid {
    grid-template-columns: 1fr;
  }

  .contact-hero h1 {
    font-size: 2.5rem;
  }

  .contact-content {
    margin: 2rem auto;
    padding: 0 1rem;
  }

  .contact-form-section,
  .faq-section {
    padding: 2rem;
  }

  .info-card {
    padding: 1.5rem;
  }
}

.success-message {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #d4edda;
  color: #155724;
  border-radius: 4px;
  text-align: center;
}

.error-message {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #f8d7da;
  color: #721c24;
  border-radius: 4px;
  text-align: center;
}
