.terms-page {
  background: linear-gradient(to bottom, #f8f9fa, #e3f2fd);
  min-height: 100vh;
  padding-bottom: 4rem;
}

.terms-hero {
  background: linear-gradient(rgba(0, 119, 182, 0.8), rgba(0, 77, 182, 0.9));
  padding: 4rem 2rem;
  text-align: center;
  color: white;
}

.terms-hero h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  font-family: "Pacifico", cursive;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.terms-hero p {
  font-size: 1.1rem;
  opacity: 0.9;
}

.terms-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: -2rem;
  position: relative;
}

.terms-section {
  margin-bottom: 2.5rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #e0e0e0;
}

.terms-section:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.terms-section h2 {
  color: #0077b6;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.terms-section p {
  color: #4a5568;
  line-height: 1.6;
  margin-bottom: 1rem;
}

.terms-section ul {
  list-style-type: none;
  padding-left: 1.5rem;
}

.terms-section ul li {
  color: #4a5568;
  margin-bottom: 0.5rem;
  position: relative;
}

.terms-section ul li::before {
  content: "•";
  color: #0077b6;
  position: absolute;
  left: -1.5rem;
  font-weight: bold;
}

.terms-section a {
  color: #0077b6;
  text-decoration: none;
  transition: color 0.3s ease;
}

.terms-section a:hover {
  color: #005d91;
  text-decoration: underline;
}

.liability-points h3 {
  color: #0077b6;
  font-size: 1.2rem;
  margin: 1.5rem 0 1rem;
  font-weight: 600;
}

.liability-notice {
  margin-top: 2rem;
  padding: 1.5rem;
  background: #f8f9fa;
  border-left: 4px solid #0077b6;
  border-radius: 4px;
}

.liability-notice p {
  margin: 0;
  font-size: 0.95rem;
}

.liability-notice strong {
  color: #0077b6;
  display: block;
  margin-bottom: 0.5rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .terms-hero {
    padding: 3rem 1rem;
  }

  .terms-hero h1 {
    font-size: 2.5rem;
  }

  .terms-content {
    padding: 1.5rem;
    margin: -1rem 1rem 0;
  }

  .terms-section h2 {
    font-size: 1.3rem;
  }

  .terms-section p,
  .terms-section ul li {
    font-size: 0.95rem;
  }

  .liability-notice {
    padding: 1rem;
  }

  .liability-points h3 {
    font-size: 1.1rem;
  }
}

/* Print Styles */
@media print {
  .terms-page {
    background: white;
  }

  .terms-hero {
    background: none;
    color: black;
    padding: 2rem 0;
  }

  .terms-content {
    box-shadow: none;
    margin: 0;
    padding: 0;
  }

  .terms-section {
    page-break-inside: avoid;
  }
}
