:root {
  --primary-blue: #0891b2;
  --secondary-blue: #0e7490;
  --accent-teal: #2dd4bf;
  --ocean-gradient: linear-gradient(135deg, #0891b2, #0e7490);
  --text-dark: #1e293b;
  --text-light: #94a3b8;
}

.blog-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  background-color: #fafafa;
  position: relative;
}

/* Enhanced Hero Section */
.hero-section1 {
  height: 90vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  margin-bottom: 0;
  overflow: hidden;
  padding: 0 1rem;
}

.surfing-lessons-img {
  background-image: url("../../../public/images/surf_lessons.jpg");
  background-position: center 30%;
  background-size: 100% auto;
}

.maui-img {
  background-image: url("../../../public/images/hawaii.jpg");
  background-position: center 30%;
  background-size: 100% auto;
}

.jacksonville-img {
  background-image: url("../../../public/images/jacksonville.jpg");
  background-position: center 30%;
  background-size: 100% auto;
}

.losangeles-img {
  background-image: url("../../../public/images/losangeles.jpg");
  background-position: center 30%;
  background-size: 100% auto;
}

.san-diego-img {
  background-image: url("../../../public/images/sandiego.jpg");
  background-position: center 30%;
  background-size: 100% auto;
}

.huntington-img {
  background-image: url("../../../public/images/huntington.jpg");
  background-position: center 30%;
  background-size: 100% auto;
}

.hero-section1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
  z-index: 1;
}

.hero-overlay1 {
  background: rgba(255, 255, 255, 0.1);
  padding: 2rem;
  border-radius: 20px;
  backdrop-filter: blur(8px);
  max-width: 800px;
  width: 90%;
  transition: all 0.4s ease;
  border: 1px solid rgba(255, 255, 255, 0.2);
  z-index: 2;
  position: relative;
  margin: 0 auto;
}

.hero-overlay1:hover {
  transform: scale(1.02);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.hero-section1 h1 {
  font-size: 5rem;
  margin-bottom: 1.5rem;
  font-weight: 800;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  color: white;
}

.hero-section1 h2 {
  font-size: 2.2rem;
  font-weight: 400;
  line-height: 1.4;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

/* Enhanced Blog Content */
.blog-content {
  padding: 4rem 2rem;
  background: white;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  margin-top: -100px;
  position: relative;
  z-index: 3;
}

.content-section {
  margin: 6rem 0;
  display: grid;
  gap: 3rem;
  opacity: 0;
  animation: fadeIn 1s ease forwards;
  position: relative;
}

.content-section::before {
  content: "";
  position: absolute;
  left: -20px;
  top: 0;
  height: 100%;
  width: 4px;
  background: var(--ocean-gradient);
  border-radius: 2px;
  transform: scaleY(0);
  transform-origin: top;
  animation: scaleIn 0.6s ease forwards 0.4s;
}

.content-section h2 {
  color: var(--primary-blue);
  font-size: 2.8rem;
  margin-bottom: 2rem;
  padding-bottom: 0.5rem;
  position: relative;
  display: inline-block;
}

.content-section h2::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: var(--ocean-gradient);
  border-radius: 2px;
  transform: scaleX(0);
  transform-origin: left;
  animation: scaleIn 0.6s ease forwards;
}

.text-content {
  line-height: 2;
  color: var(--text-dark);
  font-size: 1.1rem;
  max-width: 100%;
  position: relative;
  padding: 2rem;
  background: linear-gradient(to right, #fff, #f0f9ff);
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
}

.lead-paragraph {
  font-size: 1.5rem;
  line-height: 2;
  color: var(--text-dark);
  margin-bottom: 3rem;
  font-weight: 300;
  padding: 2rem;
  background: linear-gradient(to right, #fff, #f0f9ff);
  border-radius: 15px;
  border-left: 4px solid var(--accent-teal);
}

/* New Components */
.quote-block {
  background: var(--ocean-gradient);
  color: white;
  padding: 3rem;
  border-radius: 20px;
  margin: 4rem 0;
  position: relative;
  overflow: hidden;
}

.quote-block::before {
  content: '"';
  position: absolute;
  top: -20px;
  left: 20px;
  font-size: 8rem;
  opacity: 0.2;
  font-family: Georgia, serif;
}

.tip-box {
  background: #f0f9ff;
  border-left: 4px solid var(--accent-teal);
  padding: 2rem;
  margin: 2rem 0;
  border-radius: 0 15px 15px 0;
}

.tip-box h4 {
  color: var(--primary-blue);
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.blogs-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.blogs-title {
  text-align: center;
  color: #1a1a1a;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  position: relative;
}

.blogs-title::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background: #0066cc;
}

.blogs-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.blog-link {
  text-decoration: none;
  color: inherit;
  transition: transform 0.3s ease;
}

.blog-link:hover {
  transform: translateY(-5px);
}

.blog-card {
  background: white;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.blog-card:hover {
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
}

.blog-image {
  height: 300px;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
}

/* Add image loading styles */
.image-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #f0f0f0 0%, #e0e0e0 50%, #f0f0f0 100%);
  animation: shimmer 1.5s infinite;
  z-index: 1;
}

.image-placeholder.hidden {
  display: none;
}

.blog-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center bottom;
  display: block;
  opacity: 0;
  transition: opacity 0.3s ease;
  position: relative;
  z-index: 2;
}

.blog-image img.loaded {
  opacity: 1;
}

/* Add loading animation */
@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.blog-content {
  padding: 1.5rem;
}

.blog-content h2 {
  margin: 0 0 1rem 0;
  color: #1a1a1a;
  font-size: 1.5rem;
}

.blog-content p {
  color: #666;
  margin: 0 0 1rem 0;
  line-height: 1.6;
}

.read-more {
  color: #0066cc;
  font-weight: 500;
  display: inline-block;
  transition: transform 0.2s ease;
}

.blog-card:hover .read-more {
  transform: translateX(5px);
}

@media (max-width: 768px) {
  .blogs-container {
    padding: 1rem;
  }

  .blogs-title {
    font-size: 2rem;
    margin-top: 5rem;
  }

  .hero-section1 {
    height: 70vh;
    background-attachment: scroll;
    padding: 1rem;
  }

  .hero-section1 h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    padding: 0 1rem;
  }

  .hero-section1 h2 {
    font-size: 1.5rem;
    line-height: 1.3;
  }

  .hero-overlay1 {
    padding: 1.5rem;
    width: 90%;
    margin: 0 auto;
    margin-right: 3rem;
  }

  .losangeles-img,
  .huntington-img,
  .maui-img,
  .jacksonville-img,
  .san-diego-img {
    background-size: cover;
    background-position: center;
  }

  .blog-image {
    height: 250px;
  }
}

/* Even smaller screens */
@media (max-width: 480px) {
  .hero-section1 h1 {
    font-size: 2rem;
  }

  .hero-section1 h2 {
    font-size: 1.2rem;
  }

  .hero-overlay1 {
    padding: 1rem;
  }
}

/* Optimize specific image positions */
.huntington-image img {
  object-position: center 70%;
}

.losangeles-image img {
  object-position: center 70%;
}

.sandiego-image img {
  object-position: center 10%;
}

/* Add support for high-resolution displays */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .blog-image img {
    background-size: cover;
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes scaleIn {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}

@keyframes scaleY {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-section1 {
    height: 70vh;
  }

  .hero-section1 h1 {
    font-size: 3rem;
  }

  .hero-section1 h2 {
    font-size: 1.8rem;
  }

  .blog-content {
    padding: 2rem 1.5rem;
    margin-top: -50px;
  }

  .content-section h2 {
    font-size: 2.2rem;
  }

  .quote-block {
    padding: 2rem;
  }
}
