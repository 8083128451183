.safety-page {
  background: linear-gradient(to bottom, #f8f9fa, #e3f2fd);
  min-height: 100vh;
  padding-bottom: 4rem;
}

.safety-hero {
  background: linear-gradient(rgba(0, 119, 182, 0.8), rgba(0, 77, 182, 0.9));
  padding: 4rem 2rem;
  text-align: center;
  color: white;
}

.safety-hero h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  font-family: "Pacifico", cursive;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.safety-hero p {
  font-size: 1.1rem;
  opacity: 0.9;
}

.safety-content {
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: -2rem;
  position: relative;
}

.safety-section {
  margin-bottom: 3rem;
}

.safety-section h2 {
  color: #0077b6;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

.safety-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 1.5rem;
}

.safety-card {
  background: #f8f9fa;
  padding: 1.5rem;
  border-radius: 8px;
  border-left: 4px solid #0077b6;
}

.safety-card h3 {
  color: #0077b6;
  margin-bottom: 1rem;
  font-size: 1.3rem;
}

.safety-card ul,
.safety-guidelines ul,
.emergency-info ul,
.etiquette-rules ul {
  list-style-type: none;
  padding-left: 0;
}

.safety-card ul li,
.safety-guidelines ul li,
.emergency-info ul li,
.etiquette-rules ul li {
  margin-bottom: 0.8rem;
  padding-left: 1.5rem;
  position: relative;
  line-height: 1.6;
}

.safety-card ul li::before,
.safety-guidelines ul li::before,
.emergency-info ul li::before,
.etiquette-rules ul li::before {
  content: "•";
  color: #0077b6;
  position: absolute;
  left: 0;
  font-weight: bold;
}

.safety-disclaimer {
  background: #fff3cd;
  border: 1px solid #ffeeba;
  padding: 1.5rem;
  border-radius: 8px;
  margin-top: 2rem;
}

.safety-disclaimer h3 {
  color: #856404;
  margin-bottom: 1rem;
}

.safety-disclaimer p {
  color: #856404;
  margin-bottom: 1rem;
}

.safety-disclaimer a {
  color: #0077b6;
  text-decoration: none;
  font-weight: 500;
}

.safety-disclaimer a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .safety-hero {
    padding: 3rem 1rem;
  }

  .safety-hero h1 {
    font-size: 2.5rem;
  }

  .safety-content {
    padding: 1.5rem;
    margin: -1rem 1rem 0;
  }

  .safety-grid {
    grid-template-columns: 1fr;
  }

  .safety-card {
    padding: 1.25rem;
  }
}
