@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bangers&display=swap");

.start-your-journey {
  min-height: 100vh;
}

.hero-section {
  position: relative;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  padding: 2rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: background-image 0.3s ease-in-out;
}

/* Add fade-in animation for hero image */
.hero-section.image-loaded {
  animation: fadeIn 0.5s ease-in-out;
}

/* Remove the existing background-image declaration */
.hero-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

/* Add loading placeholder styles */
.hero-section:not(.image-loaded) {
  background: linear-gradient(135deg, #1976d2, #1565c0);
}

/* Add loading animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Add loading shimmer effect */
@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.hero-section:not(.image-loaded)::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: shimmer 2s infinite;
  background-size: 1000px 100%;
}

/* Ensure content stays above overlay */
.hero-content {
  position: relative;
  z-index: 2;
}

/* Add responsive image loading */
@media (max-width: 768px) {
  .hero-section {
    background-position: center center;
  }

  .hero-section:not(.image-loaded) {
    min-height: 50vh;
  }
}

/* Add support for high-resolution displays */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .hero-section {
    background-size: cover;
  }
}

.hero-title {
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6);
  color: white !important;
  background: none !important;
  -webkit-background-clip: unset !important;
  -webkit-text-fill-color: white !important;
  opacity: 0;
  animation: fadeSlideUp 1s ease-out forwards;
}

.hero-subtitle {
  font-size: 1.5rem;
  margin-bottom: 3rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
  opacity: 0;
  animation: fadeSlideUp 1s ease-out forwards 0.5s;
}

.search-container-start-your-journey {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  margin-top: 0;
  padding: 2rem;
  backdrop-filter: blur(5px);
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.1);
}

.search-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.search-steps {
  display: flex;
  justify-content: center;
  gap: 4rem;
  margin-bottom: 2.5rem;
}

.search-step {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: white;
}

.step-indicator {
  width: 36px;
  height: 36px;
  background: rgba(255, 255, 255, 0.2);
  border: 2px solid rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.1rem;
}

.select-wrapper {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
  justify-content: center;
}

.select-group {
  position: relative;
  flex: 1;
  max-width: 400px;
}

.select-group i {
  position: absolute;
  left: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  color: #007bff;
}

.search-select {
  width: 100%;
  padding: 1.2rem 1.2rem 1.2rem 3.5rem;
  border: none;
  border-radius: 12px;
  font-size: 1.1rem;
  background: rgba(255, 255, 255, 0.95);
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-indent: 1.5rem;
}

.search-select:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.search-select:focus {
  outline: none;
  background: white;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.3);
}

/* Safari-only selector */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  _::-webkit-full-page-media,
  _:future,
  :root .select-wrapper {
    gap: 2rem; /* Increased gap for Safari */
  }

  /* Keep existing Safari-specific select styles */
  _::-webkit-full-page-media,
  _:future,
  :root .search-select {
    text-indent: 2.5rem;
    padding-left: 2.5rem;
    height: 4rem;
  }
}

.search-button {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  display: block;
  background: #007bff;
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 8px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  margin-top: 1rem;
}

.search-button:hover {
  background: #0056b3;
  transform: translateY(-2px);
}

.search-button i {
  margin-right: 0.5rem;
}

.search-features {
  display: flex;
  justify-content: center;
  gap: 3rem;
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.feature {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  color: white;
  font-size: 1.1rem;
}

.feature i {
  color: #ffd700;
  font-size: 1.2rem;
}

/* Features Section */
.features-section {
  padding: 6rem 0;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  position: relative;
  overflow: hidden;
}

/* Add subtle wave pattern in background */
.features-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23007bff' fill-opacity='0.03' fill-rule='evenodd'/%3E%3C/svg%3E");
  opacity: 0.5;
  z-index: 0;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2.5rem;
  padding: 0 2rem;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.feature-card {
  background: rgba(255, 255, 255, 0.9);
  padding: 2.5rem 2rem;
  border-radius: 20px;
  text-align: center;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  transition: all 0.4s ease;
  border: 1px solid rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  position: relative;
  overflow: hidden;
  animation: fadeInUp 0.6s ease-out forwards;
  opacity: 0;
}

.feature-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    135deg,
    rgba(0, 123, 255, 0.1) 0%,
    rgba(0, 212, 255, 0.1) 100%
  );
  opacity: 0;
  transition: opacity 0.4s ease;
}

.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 123, 255, 0.15);
}

.feature-card:hover::before {
  opacity: 1;
}

.feature-card i {
  font-size: 3rem;
  background: linear-gradient(135deg, #007bff, #00d4ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1.5rem;
  display: inline-block;
  transform: scale(1);
  transition: transform 0.3s ease;
}

.feature-card:hover i {
  transform: scale(1.1);
}

.feature-card h3 {
  font-size: 1.8rem;
  color: #2c3e50;
  margin-bottom: 1rem;
  font-weight: 600;
  position: relative;
  padding-bottom: 1rem;
}

.feature-card h3::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 3px;
  background: linear-gradient(90deg, #007bff, #00d4ff);
  border-radius: 2px;
}

.feature-card p {
  font-size: 1.1rem;
  line-height: 1.7;
  color: #666;
  margin: 0;
}

/* Process Steps */
.process-steps {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 3rem;
  padding: 4rem;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
}

/* Add a connecting line between steps */
.process-steps::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 10%;
  right: 10%;
  height: 3px;
  background: linear-gradient(90deg, #007bff, #00d4ff);
  z-index: 0;
  transform: translateY(-50%);
}

.step-card {
  background: white;
  padding: 3rem 2rem;
  border-radius: 20px;
  position: relative;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  z-index: 1;
  border: 1px solid rgba(0, 123, 255, 0.1);
}

.step-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 40px rgba(0, 123, 255, 0.2);
}

.step-number {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 50px;
  background: linear-gradient(135deg, #007bff, #00d4ff);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  box-shadow: 0 5px 15px rgba(0, 123, 255, 0.3);
}

.step-card h3 {
  font-size: 1.8rem;
  margin: 1.5rem 0;
  color: #2c3e50;
  font-weight: 600;
  text-align: center;
}

.step-card p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #666;
  text-align: center;
  margin: 0;
  padding: 0 1rem;
}

/* Add icons to each step */
.step-card::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 2.5rem;
  color: #007bff;
  opacity: 0.1;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.step-card:nth-child(1)::before {
  content: "\f3c5"; /* Location icon */
}

.step-card:nth-child(2)::before {
  content: "\f007"; /* User icon */
}

.step-card:nth-child(3)::before {
  content: "\f073"; /* Calendar icon */
}

/* Skill Levels */
.level-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2.5rem;
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
  perspective: 1000px;
}

.level-card {
  background: rgba(255, 255, 255, 0.95);
  border-radius: 25px;
  overflow: hidden;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  transition: all 0.4s ease;
  position: relative;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  animation: cardFloat 0.8s ease-out forwards;
}

.level-card:hover {
  transform: translateY(-15px) scale(1.02);
  box-shadow: 0 20px 40px rgba(0, 123, 255, 0.2);
}

.level-header {
  padding: 2.5rem 2rem;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.level-card.beginner .level-header {
  background: linear-gradient(135deg, #00c6fb 0%, #005bea 100%);
}

.level-card.intermediate .level-header {
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
}

.level-card.advanced .level-header {
  background: linear-gradient(135deg, #f6d365 0%, #fda085 100%);
}

.level-header h3 {
  color: white;
  font-size: 2.2rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.level-tag {
  background: rgba(255, 255, 255, 0.2);
  padding: 0.5rem 1.5rem;
  border-radius: 25px;
  font-size: 0.9rem;
  color: white;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.level-features {
  padding: 2.5rem 2rem;
  list-style: none;
}

.level-features li {
  margin-bottom: 1.2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.1rem;
  color: #2c3e50;
  padding: 0.5rem 0;
  transition: transform 0.3s ease;
}

.level-features li:hover {
  transform: translateX(10px);
}

.level-features i {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 0.9rem;
}

.beginner .level-features i {
  color: #005bea;
  background: rgba(0, 91, 234, 0.1);
}

.intermediate .level-features i {
  color: #764ba2;
  background: rgba(118, 75, 162, 0.1);
}

.advanced .level-features i {
  color: #fd8f85;
  background: rgba(253, 143, 133, 0.1);
}

/* Add floating animation */
@keyframes cardFloat {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Add staggered animation delay for each card */
.level-card:nth-child(1) {
  animation-delay: 0.1s;
}
.level-card:nth-child(2) {
  animation-delay: 0.3s;
}
.level-card:nth-child(3) {
  animation-delay: 0.5s;
}

/* Add subtle wave animation to headers */
.level-header::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 120px;
  background: linear-gradient(transparent, rgba(255, 255, 255, 0.1));
  transform: skewY(-5deg);
  transform-origin: bottom;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .level-cards {
    gap: 2rem;
    padding: 1rem;
  }

  .level-header h3 {
    font-size: 1.8rem;
  }

  .level-features li {
    font-size: 1rem;
  }

  .level-header {
    padding: 2rem 1.5rem;
  }

  .level-features {
    padding: 2rem 1.5rem;
  }

  .hero-section {
    background-size: cover;
    background-position: center;
  }

  .hero-title {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    margin-top: 2rem;
  }

  .hero-subtitle {
    font-size: 1rem;
    margin-bottom: 2rem;
    padding: 0 0.5rem;
  }

  .select-wrapper {
    flex-direction: column;
  }

  .hero-content {
    position: relative;
    z-index: 2;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 1.5rem;
    margin-top: 5rem;
    border-radius: 24px;
    width: 90%;
    height: auto;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
  }

  .select-wrapper {
    flex-direction: column;
    gap: 1rem;
  }

  .select-group {
    width: 100%;
  }

  .search-select {
    width: 90%;
    font-size: 1rem;
    padding: 1.2rem 1rem 1.2rem 3.8rem;
    text-indent: 2rem;
    height: 60px;
  }

  .search-select option {
    padding-left: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    height: 50px;
  }

  .select-group i {
    left: 2rem;
    z-index: 1;
  }

  .process-steps {
    padding: 2rem;
    gap: 3rem;
  }

  .process-steps::before {
    display: none;
  }

  .step-card {
    padding: 2rem 1.5rem;
  }

  .section-title {
    font-size: 2.2rem;
    margin: 2rem 0;
  }

  .step-card h3 {
    font-size: 1.5rem;
  }

  .step-card p {
    font-size: 1rem;
  }

  .features-section {
    padding: 4rem 0;
  }

  .features-grid {
    gap: 1.5rem;
    padding: 0 1rem;
  }

  .feature-card {
    padding: 2rem 1.5rem;
  }

  .feature-card h3 {
    font-size: 1.5rem;
  }

  .feature-card p {
    font-size: 1rem;
    line-height: 1.6;
  }

  .feature-card i {
    font-size: 2.5rem;
  }

  .hero-content {
    padding: 2rem 1.5rem;
  }

  .search-steps {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }

  .select-wrapper {
    flex-direction: column;
    gap: 1rem;
  }

  .select-group {
    max-width: 100%;
  }

  .search-features {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }

  .search-container-start-your-journey {
    background: transparent;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
    border: none;
    box-shadow: none;
    padding: 1.5rem;
    border-radius: 16px;
  }

  /* If you want the content to still be accessible on mobile, but styled differently */
  .hero-content {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 1.5rem;
    margin-top: 5rem;
    border-radius: 24px;
    width: 90%;
  }
}

@keyframes fadeSlideUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Add smooth animation for cards */
.feature-card:nth-child(1) {
  animation-delay: 0.1s;
}
.feature-card:nth-child(2) {
  animation-delay: 0.2s;
}
.feature-card:nth-child(3) {
  animation-delay: 0.3s;
}
.feature-card:nth-child(4) {
  animation-delay: 0.4s;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.section-title {
  text-align: center;
  font-size: 3rem;
  margin: 3rem 0;
  color: #2c3e50;
  position: relative;
  padding-bottom: 1rem;
}

.section-title::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 4px;
  background: linear-gradient(90deg, #007bff, #00d4ff);
  border-radius: 2px;
}

/* Add animation */
.section-title {
  animation: titleFadeIn 0.8s ease-out forwards;
}

@keyframes titleFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .section-title {
    font-size: 2.2rem;
    margin: 2rem 0;
  }

  .section-title::after {
    width: 60px;
    bottom: -8px;
  }
}

/* Tablet and medium screens */
@media (min-width: 769px) and (max-width: 1024px) {
  .hero-content {
    padding: 2rem;
    max-width: 80%;
    margin-top: 4rem;
  }

  .hero-title {
    font-size: 2.5rem;
  }

  .hero-subtitle {
    font-size: 1.1rem;
  }

  .select-wrapper {
    flex-direction: row;
    gap: 1rem;
    max-width: 100%;
  }

  .select-group {
    max-width: none;
    width: 50%;
  }

  .search-select {
    padding: 0.8rem 0.8rem 0.8rem 2.5rem;
    font-size: 0.95rem;
  }

  .search-button {
    max-width: 50%;
    padding: 0.8rem 1.5rem;
  }

  /* Make feature sections more compact */
  .features-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    padding: 0 1.5rem;
    max-width: 800px;
    margin: 0 auto;
  }

  .process-steps {
    grid-template-columns: repeat(2, 1fr);
    padding: 2rem;
    max-width: 800px;
    margin: 0 auto;
  }

  .level-cards {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    padding: 1.5rem;
    max-width: 800px;
    margin: 0 auto;
  }

  .hero-section {
    background-size: cover;
    background-position: center;
  }
}
