.questions-container {
  max-width: 900px;
  margin: 2rem auto;
  padding: 2rem;
  background: linear-gradient(145deg, #ffffff, #f8f9fa);
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.05);
}

.questions-heading {
  text-align: center;
  color: #0077b6;
  font-size: 3rem;
  margin: 3rem 0 4rem;
  position: relative;
  font-weight: 700;
  background: linear-gradient(135deg, #0077b6, #023e8a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.questions-heading::after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 150px;
  height: 4px;
  background: linear-gradient(90deg, #0077b6, #023e8a);
  border-radius: 2px;
}

.question-answer {
  background: white;
  border-radius: 15px;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 119, 182, 0.1);
}

.question-answer:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 35px rgba(0, 119, 182, 0.1);
  border-color: rgba(0, 119, 182, 0.2);
}

.question-answer h2 {
  color: #0077b6;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  position: relative;
  padding-left: 2rem;
  font-weight: 600;
  line-height: 1.4;
}

.question-answer h2::before {
  content: "→";
  position: absolute;
  left: 0;
  color: #023e8a;
  font-weight: bold;
  transition: transform 0.3s ease;
}

.question-answer:hover h2::before {
  transform: translateX(5px);
}

.question-answer p {
  color: #546e7a;
  line-height: 1.8;
  margin: 0;
  padding-left: 2rem;
  font-size: 1.1rem;
  white-space: pre-line;
}

/* Add specific styling for list items */
.question-answer p span {
  display: block;
  margin-top: 0.5rem;
}

/* Style the dash at the start of each line */
.question-answer p span::before {
  content: "- ";
  color: #0077b6;
}

/* Responsive design */
@media (max-width: 768px) {
  .questions-container {
    padding: 1.5rem;
    margin: 1rem;
  }

  .questions-heading {
    font-size: 2.2rem;
    margin: 2rem 0 3rem;
  }

  .question-answer {
    padding: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .question-answer h2 {
    font-size: 1.3rem;
    padding-left: 1.8rem;
  }

  .question-answer p {
    padding-left: 1.8rem;
    font-size: 1rem;
    line-height: 1.6;
  }
}

/* Add subtle animation for loading */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.question-answer {
  animation: fadeInUp 0.5s ease forwards;
  animation-delay: calc(var(--index) * 0.1s);
  opacity: 0;
}
