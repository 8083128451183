.coach-container {
  display: flex;
  flex-direction: row; /* Aligns each coach horizontally */
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  width: 100%; /* Ensures the container spans the width */
}

.title {
  text-align: center;
}
.coach {
  display: flex; /* Flexbox layout for coach items */
  flex-direction: row; /* Ensures elements inside .coach are in a row */
  align-items: center;
  gap: 10px; /* Adds space between the items */
}

.coach p {
  margin: 0; /* Removes default margin on paragraphs */
}


button {
  margin-left: auto; /* Pushes button to the far right */
  gap: 2rem;
}

.profile-picture-journey {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  border: 4px solid white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  margin: -40px auto 1rem;
  position: relative;
  z-index: 1;
  object-fit: cover;
}

.admin-coach-card-text {
  display: flex;
  flex-direction: column;
  line-height: 0.2rem;
  margin-top: 2rem;
}

.admin-coach-card {
  height: auto;
  padding: 5rem;
  border: 0.125rem solid rgba(0, 119, 190, 0.8); /* 2px converted to rem */
  background-color: rgba(255, 255, 255, 0.9);
  padding: 1.5rem;
  border-radius: 0.625rem; /* 10px converted to rem */
  box-shadow: 0 0.25rem 0.625rem rgba(0, 0, 0, 0.2); /* 4px 10px converted to rem */
  text-align: center;
  color: black;
  transition: transform 0.3s, box-shadow 0.3s;
  width: 100%;
  padding-bottom: 3.5rem; /* 56px converted to rem */
}

.disapprove-btn {
  margin-left: 1rem;
}

.bio-text {
  line-height: 1rem;
}
