/* Modal.css */
.modal-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 1rem;
}

.modal-content {
  background: linear-gradient(145deg, #ffffff, #f8f9fa);
  padding: 6rem 4rem 3.5rem;
  border-radius: 20px;
  width: 100%;
  max-width: 1200px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 25px 50px -12px rgba(0, 119, 182, 0.25);
  animation: modalSlideIn 0.4s ease-out;
}

.modal-content h2 {
  font-size: 2.5rem;
  color: #0077b6;
  text-align: center;
  margin: 3rem 0 2rem;
  font-weight: 700;
  background: linear-gradient(135deg, #0077b6, #023e8a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.modal-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.5rem;
  margin-top: 3rem;
}

.form-field {
  position: relative;
  grid-column: span 2;
  margin-bottom: 1.5rem;
}

.form-field.half {
  grid-column: span 1;
}

.contact-input-field {
  width: 100%;
  padding: 1.2rem 1.5rem;
  border: 2px solid #e0e0e0;
  border-radius: 12px;
  font-size: 1rem;
  background: white;
  transition: all 0.3s ease;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;
  gap: 2rem;
}

.contact-input-field:focus {
  border-color: #0077b6;
  box-shadow: 0 5px 15px rgba(0, 119, 182, 0.1);
  outline: none;
}

.contact-input-field::placeholder {
  color: #94a3b8;
}

.select-level {
  height: 4rem;
}

.comments {
  height: 150px;
  resize: vertical;
  min-height: 150px;
  max-height: 300px;
  margin-bottom: 2rem;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
  width: 100%;
}

.buttons button {
  flex: 1;
  max-width: 250px;
  min-width: 200px;
  padding: 1.2rem 2rem;
  border-radius: 12px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.buttons button[type="submit"] {
  background: linear-gradient(135deg, #0077b6, #023e8a);
  color: white;
  border: none;
}

.buttons .cancel-button {
  background: white;
  color: #64748b;
  border: 2px solid #e2e8f0;
}

.close-button {
  position: absolute;
  top: 2.5rem;
  right: 2rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #94a3b8;
  padding: 0.5rem;
  border-radius: 50%;
  transition: all 0.3s ease;
  z-index: 10;
  margin-top: 4rem;
}

.contact-modal-title {
  font-size: 2.5rem;
  color: #0077b6;
  text-align: center;
  margin-top: 6rem;
  margin-bottom: 2rem;
  font-weight: 700;
  background: linear-gradient(135deg, #0077b6, #023e8a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.close-button:hover {
  color: #64748b;
  background: #f1f5f9;
  transform: rotate(90deg);
}

.agreement-section {
  grid-column: span 2;
  margin-top: 2rem;
  margin-bottom: 2.5rem;
}

.checkbox-wrapper {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  padding: 1rem;
  background: #f8fafc;
  border-radius: 12px;
}

.checkbox-custom {
  width: 20px;
  height: 20px;
  border: 2px solid #cbd5e1;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.checkbox-custom:checked {
  background: #0077b6;
  border-color: #0077b6;
}

.agreement-text {
  font-size: 0.95rem;
  color: #64748b;
  line-height: 1.6;
}

.agreement-text a {
  color: #0077b6;
  text-decoration: none;
  font-weight: 500;
}

.agreement-text a:hover {
  text-decoration: underline;
}

@keyframes modalSlideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Success State Styling */
.success-container {
  text-align: center;
  padding: 3rem;
  background: linear-gradient(135deg, #0077b6, #023e8a);
  border-radius: 20px;
  color: white;
}

.success-icon {
  font-size: 4rem;
  margin-bottom: 1.5rem;
  animation: successPop 0.5s ease-out;
}

.success-message {
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: 700;
}

.success-description {
  font-size: 1.1rem;
  opacity: 0.9;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.success-button {
  background: white;
  color: #0077b6;
  padding: 1rem 2rem;
  border-radius: 12px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.success-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

@keyframes successPop {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 768px) {
  .modal-content {
    padding: 5rem 1.5rem 2.5rem;
  }

  .modal-content h2 {
    font-size: 2rem;
    margin: 2rem 0 1.5rem;
  }

  .modal-form {
    gap: 2rem;
  }

  .buttons {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .buttons button {
    width: 100%;
    max-width: 100%;
  }

  .close-button {
    top: 2rem;
    right: 1.5rem;
  }

  .contact-modal-title {
    font-size: 2rem;
    margin-top: 7rem;
    margin-bottom: 1.5rem;
  }
  
  .level-input-field {
    height: 4rem;

  }
  .contact-input-field {
    margin-bottom: 1.5rem;
    width: 87%;
  }

  .contact-input-field.comments {
    margin-bottom: 2rem;
  }

  .agreement-section {
    margin-bottom: 2rem;
  }
}

/* Contact Success Modal Styling */
.contact-success-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
  padding: 2rem;
  animation: fadeIn 0.3s ease-out;
  margin-bottom: 2rem;
}

.contact-success-modal {
  background: linear-gradient(145deg, #ffffff, #f8f9fa);
  border-radius: 24px;
  padding: 3rem;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(255, 255, 255, 0.1);
  transform-origin: center;
  animation: modalPop 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.contact-success-content {
  text-align: center;
}

.contact-success-icon {
  font-size: 4rem;
  color: #00c853;
  margin-bottom: 1.5rem;
  animation: iconBounce 0.6s cubic-bezier(0.34, 1.56, 0.64, 1) 0.2s;
  display: inline-block;
}

.contact-success-title {
  font-size: 2.5rem;
  background: linear-gradient(135deg, #0077b6, #023e8a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1rem;
  font-weight: 700;
  animation: slideUp 0.5s ease-out 0.3s both;
}

.contact-success-message {
  color: #64748b;
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
  animation: slideUp 0.5s ease-out 0.4s both;
}

.contact-success-btn {
  background: linear-gradient(135deg, #0077b6, #023e8a);
  color: white;
  border: none;
  padding: 1rem 3rem;
  border-radius: 50px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  animation: slideUp 0.5s ease-out 0.5s both;
  box-shadow: 0 4px 15px rgba(0, 119, 182, 0.3);
}

.contact-success-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 25px rgba(0, 119, 182, 0.4);
  background: linear-gradient(135deg, #0077b6, #023e8a);
}

/* Animations (if not already present) */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes modalPop {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes iconBounce {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .contact-success-modal {
    padding: 2rem;
    margin: 1rem;
  }

  .contact-success-title {
    font-size: 2rem;
  }

  .contact-success-icon {
    font-size: 3.5rem;
  }
}
