.surf-coaches-wrapper {
  min-height: 100vh;
  background: linear-gradient(
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    url("../../../public/images/background-2.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.search-header {
  background: url("../../../public/images/surfing-photo.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 6rem 2rem;
  color: white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.search-container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  margin-top: 0;
  padding: 2rem;
  backdrop-filter: blur(5px);
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.1);
}

.coach-rating {
  display: flex;
  align-items: center;
  margin: 8px 0;
  font-size: 14px;
}

.rating-text {
  margin-left: 5px;
  color: #666;
}

.review-count {
  color: #666;
  margin-left: 5px;
}

.filled {
  color: #FFD700;
}

.empty {
  color: #e4e5e9;
}

.search-title {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  font-family: "Pacifico", cursive;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.search-location {
  font-size: 1.6rem;
  opacity: 0.95;
  margin-bottom: 2.5rem;
  color: white;
  font-weight: 500;
}

.location-form {
  max-width: 800px;
  margin: 0 auto;
}

.location-selects {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  margin-bottom: 2rem;
}

.surf-coach-select-group {
  flex: 1;
  position: relative;
  max-width: 500px;
}

.surf-coach-select-group i {
  position: absolute;
  left: 0.8rem;
  top: 50%;
  transform: translateY(-50%);
  color: #0077be;
  z-index: 1;
  font-size: 1.2rem;
}

.location-select {
  width: 100%;
  padding: 1.2rem 1rem 1.2rem 3.5rem;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 12px;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  background: rgba(255, 255, 255, 0.9);
  color: #2c3e50;
  backdrop-filter: blur(5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.location-select:hover {
  border-color: rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.95);
}

.location-select:focus {
  border-color: white;
  background: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  outline: none;
}

.location-select option {
  background: #fff;
  color: #333;
}

.results-section {
  max-width: 1400px;
  margin: 0 auto;
  padding: 3rem 2rem;
}

.results-header {
  margin-bottom: 3rem;
}

.results-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  padding: 1.5rem 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  max-width: 100%;
  gap: 2rem;
}

.results-count {
  font-size: 1.5rem;
  color: #2c3e50;
  margin: 0;
  flex: 1;
}

.sort-select {
  padding: 0.8rem 2.5rem 0.8rem 1.5rem;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 1rem;
  color: #2c3e50;
  background: white;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 200px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 0.8em auto;
}

.sort-select:hover {
  border-color: #0077be;
}

.coaches-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 2rem;
}

.coach-profile-card {
  background: white;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
}

.coach-profile-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
}

.coach-image-container {
  position: relative;
  height: 250px;
}

.coach-image {
  width: 100%;
  height: 110%;
  object-fit: cover;
  object-position: center 20%;
}

.experience-badge {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  background: rgba(0, 119, 190, 0.9);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 500;
}

.surf-coaches-wrapper .coach-info {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.surf-coaches-wrapper .coach-name {
  font-size: 1.8rem;
  color: #2c3e50;
  margin: 0;
}

.surf-coaches-wrapper .coach-location {
  color: #666;
  margin: 0.5rem 0 1.5rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  align-self: flex-start;
}

.coach-rates {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;
  width: 100%;
}

.rate-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 1.2rem;
  background: #f8f9fa;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
}

.rate-label {
  color: #666;
  font-size: 0.9rem;
}

.rate-value {
  color: #0077be;
  font-weight: 600;
  font-size: 1.1rem;
}

.view-profile-link {
  display: block;
  width: 100%;
  text-align: center;
  padding: 1rem;
  background: #0077be;
  color: white;
  text-decoration: none;
  border-radius: 8px;
  font-weight: 500;
  transition: background-color 0.3s ease;
  box-sizing: border-box;
}

.view-profile-link:hover {
  background: #005c91;
}

.loading-state {
  grid-column: 1 / -1;
  text-align: center;
  padding: 4rem;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #0077be;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto 1rem;
}

.no-results {
  grid-column: 1 / -1;
  text-align: center;
  padding: 4rem;
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .search-header {
    padding: 4rem 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .search-container {
    padding: 1.5rem;
    width: 95%;
    margin: 0 auto;
    max-width: none;
    position: relative;
    left: 45%;
    transform: translateX(-50%);
  }

  .surfcoaches-search-container {
    width: 100%;
    margin-top: 3rem;
    margin-left: 1rem;
  }
  .location-form {
    width: 100%;
  }

  .search-title {
    font-size: 2.5rem;
  }

  .search-location {
    font-size: 1.3rem;
  }

  .location-selects {
    flex-direction: column;
    gap: 1rem;
  }

  .select-group {
    max-width: 100%;
  }

  .results-info {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }

  .coaches-grid {
    grid-template-columns: 1fr;
  }

  .coach-profile-card {
    max-width: 100%;
  }

  .sort-select {
    min-width: 100%;
  }

  .location-select {
    width: 100%;
    font-size: 1rem;
    padding: 1.2rem 1rem 1.2rem 3.8rem;
    text-indent: 1.5rem;
    height: 60px;
  }

  .location-select option {
    padding-left: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    height: 50px;
  }

  .surf-coach-select-group {
    width: 100%;
  }

  .surf-coach-select-group i {
    left: 0.8rem;
    z-index: 1;
  }

  .sort-select {
    padding: 1.2rem 1rem 1.2rem 3.8rem;
    text-indent: 1.5rem;
    height: 60px;
  }

  .sort-select option {
    padding-left: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    height: 50px;
  }

  .surf-coaches-wrapper {
    background-position: center;
    background-size: cover;
    background-attachment: scroll;
  }
}

/* Safari-only selector */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  _::-webkit-full-page-media,
  _:future,
  :root .location-select {
    text-indent: 1.5rem;
    padding-left: 2.5rem;
    height: 4rem;
  }
}
