/* General styles */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");

.home {
  background-image: url("../../../public/images/background-2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: white;
  font-family: "Roboto", sans-serif;
  min-height: 100vh;
  height: auto;
  padding-bottom: 6rem;
  opacity: 0;
  transition: opacity 0.3s ease-in;
}

.home.images-loaded {
  opacity: 1;
}

.home-header {
  position: relative;
  height: 100vh;
  background-image: url("../../../public/images/img3.jpg");
  background-size: cover;
  background-position: center 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
  z-index: 1;
}

.home-header-content {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
  margin-top: 3rem;
}

.home-header-container {
  max-width: 800px;
  animation: fadeInUp 1s ease-out;
}

.hero-eyebrow {
  display: inline-block;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #0077b6;
  background: rgba(255, 255, 255, 0.9);
  padding: 0.5rem 1.5rem;
  border-radius: 50px;
  margin-bottom: 2rem;
  font-weight: 500;
}

.home-header-container h1 {
  font-size: 5rem;
  font-family: "Pacifico", cursive;
  color: white;
  line-height: 1.2;
  margin-bottom: 2rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.home-header-container p {
  font-size: 1.8rem;
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.6;
  margin-bottom: 1.5rem;
  max-width: 600px;
}

.hero-cta-group {
  display: flex;
  gap: 1.5rem;
  margin-bottom: 4rem;
}

.primary-cta {
  background: linear-gradient(135deg, #0077b6, #004080);
  color: white;
  padding: 1.2rem 2.5rem;
  border-radius: 50px;
  font-size: 1.2rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;
  transition: all 0.3s ease;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.primary-cta:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 119, 182, 0.4);
}

.secondary-cta {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  color: white;
  padding: 1.2rem 2.5rem;
  border-radius: 50px;
  font-size: 1.2rem;
  font-weight: 600;
  border: 2px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;
  transition: all 0.3s ease;
}

.secondary-cta:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-5px);
}

.hero-stats {
  display: flex;
  gap: 4rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.stat-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.stat-number {
  font-size: 2.5rem;
  font-weight: 700;
  color: white;
  font-family: "Pacifico", cursive;
}

.stat-label {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
  letter-spacing: 1px;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 1024px) {
  .home-header-container h1 {
    font-size: 4rem;
  }

  .home-header-container p {
    font-size: 1.6rem;
  }

  .hero-stats {
    gap: 2rem;
  }
}

@media (max-width: 768px) {
  .home-header-container h1 {
    font-size: 3rem;
  }

  .home-header-container p {
    font-size: 1.4rem;
  }

  .hero-cta-group {
    flex-direction: column;
  }

  .hero-stats {
    flex-direction: column;
    gap: 1.5rem;
    text-align: center;
  }

  .stat-number {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .home-header-container h1 {
    font-size: 2.5rem;
  }

  .hero-eyebrow {
    font-size: 1rem;
  }

  .home-header-container p {
    font-size: 1.2rem;
  }
}

.home-page-image {
  max-width: 100%; /* Responsive image */
  height: auto; /* Maintain aspect ratio */
  border-radius: 0.625rem; /* Rounded corners for the image */
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.home-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10rem;
}

.surf-coach-container {
  text-align: center;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.95),
    rgba(255, 255, 255, 0.85)
  );
  padding: 4rem 2rem;
  border-radius: 30px;
  margin: 6rem auto;
  max-width: 1200px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
}

.surf-coach-container h2 {
  font-size: 2.5rem;
  margin-bottom: 3rem;
  font-family: "Pacifico", cursive;
  background: linear-gradient(135deg, #0077b6, #004080);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
}

.surf-coach-container h2::after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 3px;
  background: linear-gradient(90deg, #0077b6, #004080);
  border-radius: 2px;
}

.image-text {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  align-items: center;
  margin: 2rem 0;
}

.surf-lesson-image img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 20px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease;
}

.surf-lesson-image img:hover {
  transform: translateY(-10px);
}

.surf-lesson-text ul {
  list-style: none;
  padding: 0;
  text-align: left;
}

.surf-lesson-text li {
  background: rgba(255, 255, 255, 0.5);
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
}

.surf-lesson-text li:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.8);
}

.surf-lesson-text li strong {
  color: #0077b6;
  font-size: 1.3rem;
  display: block;
  margin-bottom: 0.5rem;
  font-family: "Pacifico", cursive;
}

.coach-call-to-action {
  font-size: 1.2rem;
  color: #004080;
  font-weight: 500;
  line-height: 1.6;
  padding: 1.5rem;
  background: linear-gradient(
    135deg,
    rgba(0, 119, 182, 0.1),
    rgba(0, 64, 128, 0.1)
  );
  border-radius: 15px;
  margin-top: 2rem;
}

.find-coach-button {
  background: linear-gradient(135deg, #0077b6, #004080);
  color: white;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 2rem;
  box-shadow: 0 10px 20px rgba(0, 119, 182, 0.2);
}

.find-coach-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 119, 182, 0.3);
}

@media (max-width: 768px) {
  .image-text {
    grid-template-columns: 1fr;
  }

  .surf-lesson-image img {
    height: 300px;
  }

  .surf-coach-container {
    padding: 3rem 1.5rem;
    margin: 3rem auto;
  }

  .surf-coach-container h2 {
    font-size: 2rem;
  }

  .surf-lesson-text li strong {
    font-size: 1.2rem;
  }

  .coach-call-to-action {
    font-size: 1.1rem;
  }
}

.home-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
}

.surf-coach-container {
  text-align: center;
  max-width: 50rem;
  margin: 0 auto;
  border: 2px solid rgba(0, 119, 190, 0.8);
  background-color: rgba(255, 255, 255, 0.8);
  padding: 1rem;
  border-radius: 0.625rem;
  width: 100%;
  max-width: 62.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  text-align: center;
  color: black;
  padding: 1.5rem 2rem 3rem;
  line-height: 1.7;
}

.image-text {
  display: flex;
  align-items: center;
  margin-top: 1.25rem;
  gap: 1.25rem;
}

.surf-teaching {
  height: 13.25rem; /* Adjust the height as needed */
  object-fit: cover; /* Ensures the image scales correctly without distortion */
}

.surf-lesson-text {
  flex: 1;
  text-align: left;
}

.image-text {
  display: flex;
  flex-direction: row;
}
.surf-lesson-text ul {
  list-style-type: none;
  padding-left: 0;
}

.surf-lesson-text li {
  margin-top: 1rem;
}

.surf-coach-container button {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.surf-lesson-image img {
  height: 14rem;
  max-width: 100%;
  border-radius: 0.625rem;
}

button {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 0.9375rem 1.875rem;
  font-size: 1.2em;
  font-weight: bold;
  border-radius: 1.875rem;
  cursor: pointer;
  box-shadow: 0 0.5rem 0.625rem rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

button:hover {
  transform: translateY(-0.1875px);
  box-shadow: 0 0.75rem 1.25rem rgba(0, 0, 0, 0.4);
}

.find-coach-button {
  margin-top: 5rem;
}

.home-bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 5rem;
  gap: 2rem;
}

.for-surf-coaches,
.for-aspiring-surfers {
  border: 0.125rem solid rgba(0, 119, 190, 0.8);
  background-color: rgba(255, 255, 255, 0.8);
  padding: 1rem;
  border-radius: 0.625rem;
  max-width: 62.5rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.3);
  text-align: center;
  color: black;
  padding: 1.5rem 2rem 3rem;
  line-height: 1.7;
  margin: 1.25rem auto;
  font-size: 1.1rem;
}

.coach-heading {
  color: #0077b6;
  text-align: center;
  font-size: 2rem;
  margin-bottom: 0.9375rem;
}

.coach-text {
  margin-bottom: 0.9375rem;
  line-height: 1.6;
  text-align: left;
}

.coach-text-heading {
  max-width: 50rem;
  margin: 0 auto;
  text-align: left;
}

.coach-call-to-action {
  font-weight: bold;
  margin-top: 1.25rem;
  color: #004080;
}

.surfer-heading {
  color: #0077b6;
  text-align: center;
  font-size: 2rem;
  margin-bottom: 0.9375rem;
}

.surfer-text {
  margin-bottom: 15px;
  line-height: 1.6;
  color: black;
  text-align: left;
}

.surfer-text-heading {
  max-width: 50rem;
  text-align: left;
  margin: 0 auto;
}

.surfer-call-to-action {
  text-align: center;
  font-weight: bold;
  margin-top: 20px;
  color: #004080;
}

.surfer-call-to-action,
.coach-call-to-action {
  font-size: 1.3rem;
}

.featured-locations {
  padding: 5rem 2rem;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.95),
    rgba(255, 255, 255, 0.85)
  );
  border-radius: 30px;
  margin: 6rem auto;
  max-width: 1200px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  position: relative;
  overflow: hidden;
}

.featured-locations h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  background: linear-gradient(135deg, #0077b6, #004080);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Pacifico", cursive;
}

.location-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2.5rem;
  padding: 1rem;
}

.location-card {
  border-radius: 20px;
  overflow: hidden;
  background: white;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  position: relative;
}

.location-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 119, 182, 0.2);
}

.location-card img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.location-card:hover img {
  transform: scale(1.05);
}

.location-card h3 {
  font-size: 1.8rem;
  color: #0077b6;
  margin: 1.5rem;
  font-family: "Pacifico", cursive;
}

.location-card p {
  color: #666;
  padding: 0 1.5rem 1.5rem;
  font-size: 1.1rem;
  line-height: 1.6;
}

.quick-links {
  padding: 5rem 2rem;
  margin: 6rem auto;
  max-width: 1200px;
}

.quick-links h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  color: white;
  font-family: "Pacifico", cursive;
}

.links-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2.5rem;
}

.link-card {
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.95),
    rgba(255, 255, 255, 0.85)
  );
  padding: 2.5rem;
  border-radius: 20px;
  text-align: center;
  transition: transform 0.3s ease;
  backdrop-filter: blur(10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.link-card:hover {
  transform: translateY(-10px);
}

.link-card i {
  font-size: 2.5rem;
  color: #0077b6;
  margin-bottom: 1.5rem;
}

.link-card h3 {
  font-size: 1.5rem;
  color: #0077b6;
  margin-bottom: 1rem;
}

.link-card p {
  color: #666;
  font-size: 1.1rem;
}

/* Stats Section Styling */
.stats-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 3rem;
  padding: 5rem 2rem;
  background: linear-gradient(
    135deg,
    rgba(0, 119, 182, 0.95),
    rgba(0, 64, 128, 0.9)
  );
  color: white;
  margin: 6rem auto;
  border-radius: 30px;
  max-width: 1200px;
  box-shadow: 0 20px 40px rgba(0, 119, 182, 0.3);
  position: relative;
  overflow: hidden;
}

.stats-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.05'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  opacity: 0.1;
}

.stat-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 2.5rem;
  text-align: center;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  border: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
  overflow: hidden;
}

.stat-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );
  opacity: 0;
  transition: opacity 0.4s ease;
}

.stat-card:hover {
  transform: translateY(-10px);
  border-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.stat-card:hover::before {
  opacity: 1;
}

.stat-icon {
  font-size: 2.8rem;
  margin-bottom: 1.5rem;
  background: linear-gradient(135deg, #ffffff, #e0e0e0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  transition: transform 0.4s ease;
}

.stat-card:hover .stat-icon {
  transform: scale(1.1) rotate(5deg);
}

.stat-content {
  position: relative;
  z-index: 1;
}

.stat-content h3 {
  font-size: 3.2rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  font-family: "Pacifico", cursive;
  background: linear-gradient(135deg, #ffffff, #e0e0e0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.stat-content p {
  font-size: 1.2rem;
  opacity: 0.9;
  margin: 0;
  letter-spacing: 1px;
}

.animate-on-scroll {
  opacity: 0;
  transform: translateY(30px);
  animation: fadeInUp 0.6s ease forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .stats-section {
    padding: 3rem 1.5rem;
    gap: 2rem;
  }

  .stat-card {
    padding: 2rem;
  }

  .stat-icon {
    font-size: 2.4rem;
  }

  .stat-content h3 {
    font-size: 2.8rem;
  }

  .stat-content p {
    font-size: 1.1rem;
  }
}

.newsletter-section {
  text-align: center;
  padding: 5rem 3rem;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.95),
    rgba(255, 255, 255, 0.85)
  );
  border-radius: 30px;
  margin: 6rem auto;
  max-width: 800px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
}

.newsletter-section h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(135deg, #0077b6, #004080);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Pacifico", cursive;
}

.newsletter-section p {
  color: #666;
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.newsletter-form {
  display: flex;
  gap: 1rem;
  max-width: 500px;
  margin: 0 auto;
}

.newsletter-form input {
  flex: 1;
  padding: 1rem 1.5rem;
  border: 2px solid rgba(0, 119, 182, 0.2);
  border-radius: 50px;
  font-size: 1.1rem;
  transition: border-color 0.3s ease;
}

.newsletter-form input:focus {
  outline: none;
  border-color: #0077b6;
}

.newsletter-form button {
  padding: 1rem 2rem;
  background: linear-gradient(135deg, #0077b6, #004080);
  color: white;
  border: none;
  border-radius: 50px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.newsletter-form button:hover {
  transform: translateY(-2px);
  background: linear-gradient(135deg, #004080, #003366);
}

/* Responsive Styles */
@media (min-width: 1025px) {
  .home {
    height: auto;
    min-height: 100vh;
  }
  .home-header {
    height: 100vh;
    padding: 0rem 1.5rem;
    background-size: cover;
  }
  .home-header-container {
    max-width: 40rem;
  }

  .home-header-container h1 {
    font-size: 2.6rem;
  }

  .home-header-container p {
    font-size: 1.3rem;
  }

  .home-header-container button {
    padding: 1rem;
    font-size: 1.3rem;
  }

  .surf-coach-container h2 {
    font-size: 2rem;
  }

  .surf-coach-container,
  .for-aspiring-surfers,
  .for-surf-coaches {
    width: 80%;
    padding: 1.5rem;
    margin: 0 auto;
  }

  .surf-lesson-text li {
    font-size: 1.2rem;
  }

  .surf-lesson-text li strong {
    font-size: 1.4rem;
  }

  .find-coach-button {
    padding: 1.2rem 1.2rem;
    display: block;
    margin: 0 auto;
  }

  .surf-teaching {
    height: 5.5rem;
  }
  .surf-lesson-image img {
    height: 18rem;
  }

  .home-bottom {
    margin-top: 3rem;
    gap: 1.5rem;
  }

  .coach-heading,
  .surfer-heading {
    font-size: 1.75rem;
  }

  .coach-call-to-action,
  .surfer-call-to-action {
    font-size: 1.2rem;
  }

  .surfer-text,
  .coach-text {
    font-size: 1.2rem;
    margin-left: 8rem;
  }

  .coach-button {
    padding: 1.3rem 1.3rem;
    font-size: 1.3rem;
  }
}

@media (max-width: 992px) {
  .home {
    height: auto;
    min-height: 100vh;
    width: 100%;
  }

  .home-header {
    height: 100vh;
    background-size: cover;
    width: 100%;
  }

  .home-header-container {
    padding: 1.1rem;
  }

  .home-header-container h1 {
    font-size: 3.5rem;
  }

  .home-header-container p {
    font-size: 1.6rem;
  }

  .home-header-container button {
    padding: 1.3rem;
    font-size: 0.9rem;
  }

  .surf-coach-container h2 {
    font-size: 1.8rem;
  }
  .surf-coach-container,
  .for-aspiring-surfers,
  .for-surf-coaches {
    max-width: 80%;
    padding: 1.6rem;
    margin: 0 auto;
  }

  .surf-lesson-text li {
    font-size: 1.2rem;
  }

  .surf-lesson-text li strong {
    font-size: 1.4rem;
  }

  .find-coach-button {
    padding: 1.2rem 1.2rem;
  }

  .surf-teaching {
    height: 4.6rem;
  }
  .image-text {
    flex-direction: column;
  }

  .surf-lesson-image img {
    height: 14.1rem;
    width: 100%;
  }

  .home-bottom {
    margin-top: 1.6rem;
    gap: 0.85rem;
  }

  .coach-heading,
  .surfer-heading {
    font-size: 1.35rem;
  }

  .surfer-text,
  .coach-text {
    font-size: 1.2rem;
  }

  .coach-button {
    padding: 1.2rem 1.2rem;
    font-size: 1.2rem;
  }
  .coach-call-to-action,
  .surfer-call-to-action {
    font-size: 1.1rem;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .home {
    height: auto;
    min-height: 200rem;
  }

  .home-header {
    height: 100vh;
    background-size: cover;
  }

  .home-header-container {
    padding: 1rem;
  }

  .home-header-container h1 {
    font-size: 2.15rem;
  }

  .home-header-container p {
    font-size: 1.1rem;
    width: 90%;
  }

  .home-header-container button {
    padding: 1.2rem;
    font-size: 1rem;
  }

  .surf-coach-container h2 {
    font-size: 1.7rem;
  }
  .surf-coach-container,
  .for-aspiring-surfers,
  .for-surf-coaches {
    max-width: 80%;
    padding: 1.5rem;
    margin: 0 auto;
  }

  .surf-lesson-text li {
    font-size: 1.1rem;
  }

  .surf-lesson-text li strong {
    font-size: 1.3rem;
  }

  .find-coach-button {
    padding: 1.1rem 1.1rem;
  }

  .surf-teaching {
    height: 4.5rem;
  }
  .image-text {
    flex-direction: column;
  }

  .surf-lesson-image img {
    height: 14rem;
    width: 100%;
  }

  .home-bottom {
    margin-top: 1.5rem;
    gap: 0.75rem;
  }

  .coach-heading,
  .surfer-heading {
    font-size: 1.25rem;
  }

  .surfer-text,
  .coach-text {
    font-size: 1.1rem;
  }

  .coach-button {
    padding: 1.1rem 1.1rem;
    font-size: 1.1rem;
  }
  .coach-call-to-action,
  .surfer-call-to-action {
    font-size: 1rem;
  }
}

@media (max-width: 600px) {
  .home {
    height: auto;
    min-height: 200rem;
  }

  .home-header {
    height: 100vh;
    min-height: 600px;
    padding: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-position: center;
    position: relative;
  }

  .hero-overlay {
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );
  }

  .home-header-content {
    width: 100%;
    padding: 3rem 1.5rem 0;
    z-index: 2;
    display: flex;
    justify-content: center;
  }

  .home-header-container {
    width: 100%;
    max-width: 100%;
    text-align: center;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .hero-eyebrow {
    font-size: 0.9rem;
    padding: 0.4rem 1rem;
    margin-bottom: 1.5rem;
    letter-spacing: 2px;
  }

  .home-header-container h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    line-height: 1.2;
  }

  .home-header-container p {
    font-size: 1.1rem;
    margin-bottom: 2rem;
    line-height: 1.4;
    padding: 0 0.5rem;
  }

  .hero-cta-group {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 1rem;
  }

  .primary-cta {
    padding: 0.9rem 1.8rem;
    font-size: 1rem;
    width: auto;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin: 0 auto;
  }

  .primary-cta i {
    font-size: 0.9rem;
  }

  /* Add smooth animation for content */
  .home-header-container {
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUpMobile 0.8s ease forwards;
  }

  @keyframes fadeInUpMobile {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  /* Add a subtle text shadow for better readability */
  .home-header-container h1,
  .home-header-container p {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  }
}

/* Add specific styles for very small devices */
@media (max-width: 360px) {
  .home-header-container h1 {
    font-size: 2rem;
  }

  .home-header-container p {
    font-size: 1rem;
  }

  .primary-cta {
    padding: 0.8rem 1.5rem;
    font-size: 0.9rem;
  }
}

/* Add styles for landscape mode on mobile */
@media (max-height: 600px) and (orientation: landscape) {
  .home-header {
    height: auto;
    min-height: 100vh;
    padding: 2rem 1rem;
  }

  .home-header-container h1 {
    font-size: 2rem;
    margin-bottom: 0.8rem;
  }

  .home-header-container p {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
}

.testimonials {
  padding: 8rem 0;
  position: relative;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.95)
  );
  border-radius: 40px;
  margin: 4rem 0;
  overflow: hidden;
}

/* Add wave pattern background */
.testimonials::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%230077b6' fill-opacity='0.05'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.testimonials h3 {
  text-align: center;
  font-size: 3.2rem;
  margin-bottom: 5rem;
  background: linear-gradient(135deg, #0077b6, #00d4ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  font-weight: 700;
}

.testimonials h3::after {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 150px;
  height: 4px;
  background: linear-gradient(90deg, #0077b6, #00d4ff, #0077b6);
  border-radius: 4px;
}

.testimonial-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4rem;
  padding: 0 4rem;
  max-width: 1400px;
  margin: 0 auto;
}

.testimonial-card {
  background: white;
  border-radius: 30px;
  padding: 3.5rem;
  position: relative;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 20px 40px rgba(0, 119, 182, 0.1);
  border: 1px solid rgba(0, 119, 182, 0.1);
  overflow: hidden;
  margin-right: 2rem;
}

.testimonial-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(0, 119, 182, 0.05), transparent);
  opacity: 0;
  transition: opacity 0.4s ease;
}

.testimonial-card:hover {
  transform: translateY(-15px) scale(1.02);
  box-shadow: 0 30px 60px rgba(0, 119, 182, 0.15);
}

.testimonial-card:hover::before {
  opacity: 1;
}

.testimonial-content {
  position: relative;
  z-index: 1;
}

.testimonial-content i {
  font-size: 3rem;
  background: linear-gradient(135deg, #0077b6, #00d4ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 2rem;
  display: block;
  transform: rotate(-8deg);
  transition: transform 0.4s ease;
}

.testimonial-card:hover i {
  transform: rotate(0deg) scale(1.1);
}

.testimonial-content p {
  font-size: 1.3rem;
  line-height: 1.8;
  color: #2c3e50;
  margin-bottom: 2.5rem;
  font-style: italic;
  position: relative;
  padding-left: 1rem;
  border-left: 3px solid rgba(0, 119, 182, 0.2);
}

.testimonial-author {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding-top: 2rem;
  border-top: 2px solid rgba(0, 119, 182, 0.1);
  position: relative;
}

.testimonial-author::before {
  content: "";
  position: absolute;
  top: -2px;
  left: 0;
  width: 50px;
  height: 2px;
  background: linear-gradient(90deg, #0077b6, #00d4ff);
}

.testimonial-author h4 {
  font-size: 1.4rem;
  color: #2c3e50;
  margin: 0;
  font-weight: 700;
  background: linear-gradient(135deg, #2c3e50, #3498db);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.testimonial-author p {
  font-size: 1.1rem;
  color: #666;
  margin: 0.3rem 0 0;
  font-style: normal;
  border: none;
  padding: 0;
}

/* Add floating animation */
@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.testimonial-card {
  animation: float 6s ease-in-out infinite;
}

.testimonial-card:nth-child(2) {
  animation-delay: 3s;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .testimonial-grid {
    grid-template-columns: 1fr;
    gap: 3rem;
    padding: 0 2rem;
  }

  .testimonials h3 {
    font-size: 2.8rem;
  }
}

@media (max-width: 768px) {
  .testimonials {
    padding: 6rem 1rem;
  }

  .testimonial-card {
    padding: 1rem;
    width: 100%;
    margin: 0 auto;
  }

  .testimonial-content p {
    font-size: 1.2rem;
    padding: 0.5rem 0.5rem;
    margin-bottom: 1.5rem;
    line-height: 1.6;
    /* Remove left border on mobile for more space */
    border-left: none;
    text-align: center;
  }

  .testimonial-grid {
    padding: 0 1rem;
  }
}

@media (max-width: 480px) {
  .testimonial-card {
    width: 95%;
  }

  .testimonial-content p {
    font-size: 1.1rem;
    padding: 0.25rem;
  }
}

/* Legal and Safety Notice Styling */
.legal-notice,
.coach-legal-notice {
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.95),
    rgba(255, 255, 255, 0.85)
  );
  padding: 2rem;
  border-radius: 20px;
  margin: 2rem auto;
  max-width: 1000px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  border-left: 4px solid #0077b6;
  transition: all 0.3s ease;
  text-align: center;
}

.legal-notice:hover,
.coach-legal-notice:hover {
  transform: translateY(-5px);
  box-shadow: 0 20px 40px rgba(0, 119, 182, 0.2);
}

.legal-notice p {
  color: #333;
  font-size: 1.1rem;
  line-height: 1.6;
}

.legal-link {
  color: #0077b6;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
  padding: 0.3rem 0.8rem;
  border-radius: 15px;
  background: rgba(0, 119, 182, 0.1);
}

.legal-link:hover {
  color: #004080;
  background: rgba(0, 119, 182, 0.2);
  transform: translateY(-2px);
}

/* Safety Reminder Section */
.safety-reminder {
  background: linear-gradient(
    135deg,
    rgba(0, 119, 182, 0.05),
    rgba(0, 119, 182, 0.1)
  );
  padding: 2.5rem;
  border-radius: 20px;
  margin: 3rem auto;
  max-width: 1000px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.safety-content h3 {
  color: #0077b6;
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
  font-family: "Pacifico", cursive;
  background: linear-gradient(135deg, #0077b6, #004080);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.safety-links {
  display: flex;
  justify-content: center;
  gap: 2.5rem;
  margin-top: 1.5rem;
}

.safety-link {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  color: #0077b6;
  text-decoration: none;
  padding: 1rem 1.5rem;
  border-radius: 15px;
  background: white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.safety-link:hover {
  transform: translateY(-5px);
  background: #0077b6;
  color: white;
  box-shadow: 0 15px 30px rgba(0, 119, 182, 0.3);
}

.coach-legal-notice ul {
  list-style: none;
  padding: 0;
  margin: 1.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.important-note {
  font-size: 1rem;
  color: #004080;
  margin-top: 1.5rem;
  padding: 1rem;
  background: rgba(0, 119, 182, 0.1);
  border-radius: 10px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .safety-links {
    flex-direction: column;
    gap: 1rem;
  }

  .safety-link {
    justify-content: center;
  }

  .legal-notice,
  .coach-legal-notice,
  .safety-reminder {
    margin: 2rem 1rem;
    padding: 1.5rem;
  }

  .legal-notice p {
    font-size: 1rem;
  }

  .legal-link {
    display: inline-block;
    margin: 0.3rem;
  }
}

/* Dark Mode Support */
@media (prefers-color-scheme: dark) {
  .legal-notice,
  .coach-legal-notice {
    background: linear-gradient(135deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7));
  }

  .legal-notice p {
    color: #fff;
  }

  .legal-link {
    background: rgba(255, 255, 255, 0.1);
  }

  .legal-link:hover {
    background: rgba(255, 255, 255, 0.2);
  }
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
  margin: 4rem 0;
  padding: 0 2rem;
}

.feature-card {
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.95),
    rgba(255, 255, 255, 0.85)
  );
  padding: 3rem 2rem;
  border-radius: 25px;
  text-align: center;
  transition: all 0.4s ease;
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
}

.feature-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, transparent, rgba(0, 119, 182, 0.05));
  pointer-events: none;
}

.feature-card:hover {
  transform: translateY(-15px);
  box-shadow: 0 20px 40px rgba(0, 119, 182, 0.2);
  border-color: rgba(0, 119, 182, 0.3);
}

.feature-card i {
  font-size: 3rem;
  background: linear-gradient(135deg, #0077b6, #004080);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1.5rem;
  display: inline-block;
  transition: transform 0.3s ease;
}

.feature-card:hover i {
  transform: scale(1.1);
}

.feature-card h3 {
  color: #004080;
  margin-bottom: 1.2rem;
  font-size: 1.8rem;
  font-weight: 600;
  font-family: "Pacifico", cursive;
}

.feature-card p {
  color: #2c3e50;
  line-height: 1.7;
  font-size: 1.1rem;
  margin: 0;
  padding: 0 1rem;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .features-grid {
    gap: 2rem;
    padding: 0 1rem;
  }
}

@media (max-width: 768px) {
  .features-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .feature-card {
    padding: 2.5rem 1.5rem;
  }

  .feature-card h3 {
    font-size: 1.6rem;
  }

  .feature-card p {
    font-size: 1rem;
  }
}

/* New styles for personalized experience section */
.experience-section {
  margin: 6rem auto;
  padding: 4rem 2rem;
  background: linear-gradient(
    135deg,
    rgba(0, 119, 182, 0.1),
    rgba(0, 64, 128, 0.15)
  );
  border-radius: 30px;
  text-align: center;
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(10px);
}

.experience-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(90deg, transparent, #0077b6, transparent);
}

.experience-content {
  max-width: 800px;
  margin: 0 auto;
}

.experience-content i {
  font-size: 3.5rem;
  background: linear-gradient(135deg, #0077b6, #004080);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 2rem;
  display: inline-block;
}

.experience-content h3 {
  font-size: 2.5rem;
  color: #004080;
  margin-bottom: 1.5rem;
  font-family: "Pacifico", cursive;
}

.experience-content p {
  font-size: 1.3rem;
  color: #2c3e50;
  line-height: 1.8;
  margin-bottom: 2.5rem;
}

.experience-cta {
  background: linear-gradient(135deg, #0077b6, #004080);
  color: white;
  border: none;
  padding: 1.2rem 2.5rem;
  font-size: 1.2rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 10px 20px rgba(0, 119, 182, 0.2);
}

.experience-cta:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 119, 182, 0.3);
}

/* Update responsive styles */
@media (max-width: 1024px) {
  .features-grid {
    gap: 2rem;
    padding: 0 1rem;
  }

  .experience-section {
    padding: 3rem 1.5rem;
  }
}

@media (max-width: 768px) {
  .features-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .experience-content h3 {
    font-size: 2rem;
  }

  .experience-content p {
    font-size: 1.1rem;
  }
}

/* Info Sections Styling */
.info-sections {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  padding: 5rem 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.info-section {
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.95),
    rgba(255, 255, 255, 0.85)
  );
  border-radius: 30px;
  padding: 3rem;
  backdrop-filter: blur(10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
}

.info-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(90deg, transparent, #0077b6, transparent);
}

.info-section:hover {
  transform: translateY(-10px);
  box-shadow: 0 25px 50px rgba(0, 119, 182, 0.2);
}

.info-content h3 {
  font-size: 2.5rem;
  color: #0077b6;
  margin-bottom: 2.5rem;
  text-align: center;
  font-family: "Pacifico", cursive;
  background: linear-gradient(135deg, #0077b6, #004080);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.info-features {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin-bottom: 2.5rem;
}

.info-feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  transition: transform 0.3s ease;
}

.info-feature:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.8);
}

.info-feature i {
  font-size: 2.5rem;
  color: #0077b6;
  margin-bottom: 1rem;
  background: linear-gradient(135deg, #0077b6, #004080);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.info-feature h4 {
  font-size: 1.4rem;
  color: #004080;
  margin-bottom: 0.8rem;
  font-weight: 600;
}

.info-feature p {
  font-size: 1.1rem;
  color: #2c3e50;
  line-height: 1.6;
}

.cta-button {
  display: block;
  margin: 2rem auto 0;
  padding: 1.2rem 2.5rem;
  font-size: 1.2rem;
  background: linear-gradient(135deg, #0077b6, #004080);
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 10px 20px rgba(0, 119, 182, 0.2);
}

.cta-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 119, 182, 0.3);
  background: linear-gradient(135deg, #004080, #003366);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .info-sections {
    grid-template-columns: 1fr;
    padding: 3rem 1.5rem;
  }

  .info-section {
    padding: 2.5rem;
  }

  .info-content h3 {
    font-size: 2rem;
  }

  .info-feature h4 {
    font-size: 1.3rem;
  }

  .info-feature p {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .info-section {
    padding: 2rem;
  }

  .info-content h3 {
    font-size: 1.8rem;
  }

  .info-feature {
    padding: 1.2rem;
  }

  .info-feature i {
    font-size: 2rem;
  }

  .cta-button {
    padding: 1rem 2rem;
    font-size: 1.1rem;
  }
}

/* Add this to remove underlines from links containing buttons */
a {
  text-decoration: none;
}

/* Or more specifically for buttons inside links */
a button {
  text-decoration: none;
}

/* Only change color for mobile devices */
@media (max-width: 768px) {
  .legal-notice p {
    color: white !important;
  }

  .legal-link {
    color: white;
    background: rgba(255, 255, 255, 0.1);
  }

  .legal-link:hover {
    background: rgba(255, 255, 255, 0.2);
  }
}

/* Image loading states */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f8f9fa;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #0077b6;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Image optimization and responsive sizing */
.cta-container {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.cta-image {
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: cover;
  border-radius: 20px;
  opacity: 0;
  transition: opacity 0.3s ease-in, transform 0.3s ease;
  transform: translateZ(0);
  will-change: opacity;
  backface-visibility: hidden;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.cta-image.image-loaded {
  opacity: 1;
}

.cta-image:hover {
  transform: scale(1.02);
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .cta-container {
    padding: 1.5rem;
  }
  
  .cta-image {
    max-height: 400px;
  }
}

@media (max-width: 768px) {
  .cta-container {
    padding: 1rem;
  }
  
  .cta-image {
    max-height: 300px;
  }
}

@media (max-width: 480px) {
  .cta-container {
    padding: 0.5rem;
  }
  
  .cta-image {
    max-height: 250px;
  }
}
