.about-page {
  background: linear-gradient(to bottom, #f8f9fa, #e3f2fd);
  color: #2c3e50;
  font-family: "Roboto", sans-serif;
  min-height: 100vh;
  padding-bottom: 4rem;
}

.about-hero {
  position: relative;
  background-size: cover;
  background-position: center;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  padding: 2rem;
  transition: background-image 0.3s ease-in-out;
}

.about-hero h1 {
  font-size: 4rem;
  font-family: "Pacifico", cursive;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.about-hero p {
  font-size: 1.5rem;
  opacity: 0.9;
}

.about-section {
  padding: 6rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.content-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  align-items: center;
}

.content-wrapper.reverse {
  direction: rtl;
}

.content-wrapper.reverse .text-content {
  direction: ltr;
}

.text-content h2 {
  font-size: 2.5rem;
  color: #0077b6;
  margin-bottom: 1.5rem;
  font-family: "Pacifico", cursive;
}

.text-content p {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #546e7a;
}

.image-wrapper {
  position: relative;
  background: #f0f0f0;
  border-radius: 20px;
  overflow: hidden;
}

.image-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #f0f0f0 0%, #e0e0e0 50%, #f0f0f0 100%);
  animation: shimmer 1.5s infinite;
  z-index: 1;
}

.about-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  object-position: center 80%;
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 0;
  position: relative;
  z-index: 2;
}

.about-image.loaded {
  opacity: 1;
}

.about-image.loaded + .image-wrapper::before {
  display: none;
}

.about-image:hover {
  transform: translateY(-10px);
}

.values-section {
  background: linear-gradient(135deg, #0077b6, #004080);
  color: white;
  border-radius: 30px;
  margin: 4rem auto;
  padding: 4rem 2rem;
}

.values-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
}

.commitment-section h2 {
  text-align: center;
}

.values-section h2 {
  text-align: center;
}

.value-card {
  background: rgba(255, 255, 255, 0.1);
  padding: 2rem;
  border-radius: 20px;
  text-align: center;
  backdrop-filter: blur(10px);
  transition: transform 0.3s ease;
}

.value-card:hover {
  transform: translateY(-5px);
}

.value-card i {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.impact-stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 2rem;
}

.stat {
  text-align: center;
}

.stat h3 {
  font-size: 2.5rem;
  color: #0077b6;
  font-family: "Pacifico", cursive;
}

.commitment-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
}

.commitment-card {
  background: white;
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.commitment-card:hover {
  transform: translateY(-5px);
}

.cta-section {
  text-align: center;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.95),
    rgba(255, 255, 255, 0.85)
  );
  border-radius: 30px;
  padding: 4rem 2rem;
  margin: 4rem auto;
  max-width: 100%;
  width: 100%;
}

.cta-buttons {
  display: flex;
  gap: 2rem;
  justify-content: center;
  margin-top: 2rem;
}

.cta-button {
  padding: 1rem 2rem;
  font-size: 1.1rem;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.cta-button.primary {
  background: linear-gradient(135deg, #0077b6, #004080);
  color: white;
}

.cta-button.secondary {
  background: white;
  color: #0077b6;
  border: 2px solid #0077b6;
}

.cta-button:hover {
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .content-wrapper {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .about-section {
    margin: 0 auto;
  }
  .cta-section {
    margin: 0;
    padding: 2rem 1rem;
    width: auto;
    box-sizing: border-box;
  }
  .cta-section h2,
  .cta-section p {
    margin-left: 1rem;
    margin-right: 1rem;
    width: auto;
    max-width: none;
  }
  .cta-section h2 {
    margin: 0 auto 1rem;
    max-width: 90%;
    font-size: 1.8rem;
  }
  .cta-section p {
    text-align: center;
    padding: 0 1rem;
    margin: 0 auto 2rem;
    max-width: 90%;
    font-size: 1.1rem;
  }
  .about-hero h1 {
    font-size: 2.5rem;
  }

  .impact-stats {
    grid-template-columns: 1fr;
  }

  .cta-buttons {
    width: auto;
    padding: 0 1rem;
  }

  .cta-button {
    width: 100%;
    max-width: none;
  }

  .cta-buttons a {
    width: 100%;
  }

  .about-section {
    padding: 3rem 1.5rem;
    margin: 0 auto;
  }

  .about-hero {
    background-position: center center;
    height: 50vh;
  }

  .about-image {
    height: 300px;
  }
}

.links-grid a {
  text-decoration: none;
}

.link-card {
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.95),
    rgba(255, 255, 255, 0.85)
  );
  padding: 2.5rem;
  border-radius: 20px;
  text-align: center;
  transition: transform 0.3s ease;
  backdrop-filter: blur(10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

/* Add fade-in animation for hero image */
.about-hero.image-loaded {
  animation: fadeIn 0.5s ease-in-out;
}

/* Loading state styles */
.about-hero:not(.image-loaded) {
  background: linear-gradient(135deg, #0077b6, #004080);
}

/* Add shimmer effect during loading */
.about-hero:not(.image-loaded)::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: shimmer 2s infinite;
  background-size: 1000px 100%;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

/* High-resolution display support */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .about-hero,
  .about-image {
    background-size: cover;
  }
}
