.blog-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .hero {
   
    background-size: cover;
    background-position: center;
    color: white;
    padding: 100px 20px;
    text-align: center;
    border-radius: 15px;
    margin: 20px 0;
  }
  
  .content-wrapper {
    display: grid;
    gap: 40px;
    padding: 40px 0;
  }
  
  .content-card {
    background: white;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .benefits-grid .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }
  
  .benefit-card {
    background: #f8f9fa;
    padding: 20px;
    border-radius: 10px;
    transition: transform 0.3s ease;
  }
  
  .benefit-card:hover {
    transform: translateY(-5px);
  }
  
  .season-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }
  
  .cta-section {
    text-align: center;
    padding: 60px 20px;
    background: #f8f9fa;
    border-radius: 15px;
    margin: 40px 0;
  }
  
  .cta-button {
    background: #0066cc;
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 25px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .cta-button:hover {
    background: #0052a3;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .hero {
      padding: 60px 20px;
    }
    
    .content-wrapper {
      gap: 20px;
    }
  }